
let config = {}

export function getConfig () {
  return config
}

export function setConfig (configuration) {
  config = configuration
}
