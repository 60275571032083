<template>
  <div class="page">
    <div class="container">
      <div class="menu">
        <template v-if="locationId">
          <div class="featureButtonFixed menuFeature-pan">
            <a
              class="featureButton"
              @click="togglePan"
            >
              <font-awesome-icon icon="expand" /> Pan
            </a>
          </div>
          <div class="featureButtonFixed menuFeature-highres">
            <router-link
              :to="enhancedUrl"
              class="featureButton"
              @click.native="standardTracker('highres', locationId)"
            >
              <font-awesome-icon icon="border-style" /> High Res
            </router-link>
          </div>
          <div
            v-if="locationId.indexOf('conus') === 0"
            class="featureButtonFixed menuFeature-enlarge"
          >
            <a
              class="featureButton"
              @click="toggleSize"
            >
              <template v-if="locationId === 'conus-large'">
                <font-awesome-icon icon="th-large" /> Normal
              </template>
              <template v-else>
                <font-awesome-icon icon="th" /> Enlarge
              </template>
            </a>
          </div>
        </template>
        <div class="featureButtonExpand menuFeature-location">
          <a
            class="featureButton"
            @click="togglePanel('location')"
          >
            <font-awesome-icon icon="search" /> {{ locationLabel }}
          </a>
        </div>
      </div>
      <div
        v-show="!isRegionRoute"
        class="productBar"
        @click="togglePanel('products')"
      >
        <span class="productName">
          Local radar {{ productName }}
        </span>
        <span
          class="productArrow"
        >
          <font-awesome-icon :icon="['fas', 'angle-down']" />
        </span>
      </div>
      <div class="map">
        <div class="mapImages">
          <img
            v-if="currentImageUrl"
            ref="standardmap"
            :src="currentImageUrlWithRefresh"
            class="mapImage"
            @load="onMapLoad"
          >
          <div
            v-else
            class="mapInvalidImage"
          >
            Invalid station
          </div>
        </div>
        <div class="mapOverlays">
          <div
            v-if="mapLoading === 2"
            class="mapOverlay-loading mapMessage"
          >
            Loading map for {{ locationId }}
          </div>
          <div
            v-if="locationId && panToggle"
            class="mapOverlay-pan"
          >
            <router-link
              v-for="(location, dir) in locationNeighbors"
              :key="`dir${dir}`"
              :class="`mapPanButton mapPanButton-${dir.toUpperCase()}`"
              :to="`/${locationType}/${location.toLowerCase()}/standard`"
              @click.native="standardTracker('panto', location)"
            >
              <font-awesome-icon icon="arrow-circle-up" />
            </router-link>
            <template v-if="!locationNeighbors">
              <div class="mapMessage">
                {{ locationLabel }} cannot pan, please select another region or station.
              </div>
            </template>
          </div>
          <div
            v-if="activePanel !== null"
            class="mapOverlay-panels"
          >
            <div
              v-if="activePanel === 'location'"
              :class="{ mapPanelFullSize: viewPanelFullSize }"
              class="mapPanel mapPanel-location"
            >
              <div class="mapPanelContent">
                <template v-if="locationPanelMetadata">
                  <div class="mapPanelTitle">
                    {{ locationPanelMetadata.title }}
                  </div>
                  <div
                    v-if="locationPanelMetadata.secondaryTitle"
                    class="mapPanel-location-mapPanelSecondaryTitle"
                  >
                    {{ locationPanelMetadata.secondaryTitle }}
                  </div>
                  <hr>
                </template>
                Search for location
                <input
                  v-model="searchFilter"
                  type="text"
                >
                <div class="mapPanel-locationSearchType">
                  <input
                    v-model="searchType"
                    type="radio"
                    value="station"
                  ><span @click="searchType = 'station'">Stations</span>
                  <input
                    v-model="searchType"
                    type="radio"
                    value="region"
                  ><span @click="searchType = 'region'">Regions</span>
                </div>
                <div class="mapPanel-locationResults">
                  <ul
                    v-if="filteredLocations.length > 0"
                  >
                    <li
                      v-for="location in filteredLocations"
                      :key="`location${location.id}`"
                    >
                      <router-link
                        :to="location.href"
                        @click.native="selectLocation(location.id)"
                      >
                        {{ location.label }}
                      </router-link>
                      <span
                        v-if="location.secondaryLabel"
                      >
                        <br>
                        {{ location.secondaryLabel }}
                      </span>
                    </li>
                  </ul>
                  <span v-else>
                    No {{ searchType }}s found using that filter.
                    <a
                      href=""
                      @click.prevent="searchFilter = ''"
                    >Clear the filter</a> to see all {{ searchType }}s.
                  </span>
                </div>
              </div>
              <div class="mapPanelActionBar">
                <input
                  type="button"
                  value="Close"
                  @click="togglePanel()"
                >
              </div>
            </div>
            <div
              v-if="activePanel === 'products'"
              class="mapPanel mapPanel-product"
            >
              <div class="mapPanelContent">
                <div class="mapPanelTitle">
                  Products
                </div>
                <hr>
                <ul>
                  <li
                    v-for="product in getProducts()"
                    :key="product.href"
                    @click="togglePanel('products')"
                  >
                    <router-link :to="product.href">
                      {{ product.longText }}
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="mapPanelActionBar">
                <input
                  type="button"
                  value="Close"
                  @click="togglePanel()"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="locationId"
        class="controls"
      >
        <div
          class="featureButton featureButtonFixed controlFeature-auto"
          @click="autoPlayback"
        >
          <font-awesome-icon
            :class="{
              'controlFeature-autoIcon': true,
              'controlFeature-autoEnabled': playbackAuto
            }"
            icon="toggle-on"
          />
          Auto <span v-if="playbackAuto">Playback</span>
        </div>
        <template v-if="!playbackAuto">
          <a
            class="featureButton featureButtonFixed controlFeature-back"
            @click="backPlayback"
          >
            <font-awesome-icon icon="backward" />
          </a>
          <a
            class="featureButton featureButtonFixed controlFeature-play"
            @click="togglePlayback"
          >
            <font-awesome-icon
              v-if="!playbackToggle"
              icon="play"
            />
            <font-awesome-icon
              v-else
              icon="pause"
            />
          </a>
          <a
            class="featureButton featureButtonFixed controlFeature-forward"
            @click="forwardPlayback"
          >
            <font-awesome-icon icon="forward" />
          </a>
          <a
            class="featureButton featureButtonFixed controlFeature-speeddown"
            @click="changePlaybackSpeed(-1)"
          >
            <font-awesome-icon icon="caret-square-left" />
          </a>
          <div
            class="featureValue featureValueFixed controlFeature-speed"
          >
            <span
              v-for="speed in playbackMaxSpeed"
              :key="`speed${speed}`"
              class="controlFeature-speedStep"
              :class="{'controlFeature-speedStep-active':speed <= playbackSpeed}"
            >
              &nbsp;
            </span>
          </div>
          <a
            class="featureButton featureButtonFixed controlFeature-speedup"
            @click="changePlaybackSpeed(1)"
          >
            <font-awesome-icon icon="caret-square-right" />
          </a>
          <div class="controlFeature-frameNumber">
            {{ playbackMaxIndex - playbackIndex + 1 }} of {{ playbackMaxIndex + 1 }}
          </div>
        </template>
      </div>
      <div class="info">
        <template v-if="officeUrl && officeLabel">
          <div class="infoLabel">
            Office
          </div>
          <div class="infoValue">
            <a :href="officeUrl">{{ officeLabel }}</a>
          </div>
        </template>
        <div
          v-if="locationId"
          class="infoLabel"
        >
          Page Updated
        </div>
        <div class="infoValue">
          {{ imageUpdatedLabel }}
        </div>
        <template v-if="locationId">
          <div class="infoLabel">
            Image Loop
          </div>
          <div class="infoValue">
            <a :href="autoImageUrl">{{ autoImageLabel }}</a>
          </div>
          <div class="infoLabel">
            Latest Image
          </div>
          <div class="infoValue">
            <a :href="latestImageUrl">{{ latestImageLabel }}</a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { STATIONS, REGIONS, PRODUCTS } from '~/config'
import { DateTime, Duration } from 'luxon'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowCircleUp,
  faBackward,
  faBorderStyle,
  faCaretSquareLeft,
  faCaretSquareRight,
  faExpand,
  faForward,
  faPlay,
  faPause,
  faTh,
  faThLarge,
  faToggleOn,
  faToggleOff,
  faSearch,
  faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faArrowCircleUp,
  faBackward,
  faBorderStyle,
  faCaretSquareLeft,
  faCaretSquareRight,
  faAngleDown,
  faExpand,
  faForward,
  faPlay,
  faPause,
  faTh,
  faThLarge,
  faToggleOn,
  faToggleOff,
  faSearch)

export default {
  components: {
    FontAwesomeIcon
  },
  data () {
    return {
      activePanel: null,
      compass: ['nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w'],
      imageUpdatedAt: null,
      imageAutoUpdatedAt: null,
      locationId: null,
      locationType: null,
      locationMetadata: null,
      mapLoading: 0,
      pageUpdated: DateTime.now(),
      panToggle: false,
      playbackAuto: true,
      playbackIndex: 0,
      playbackLatestIndex: 0,
      playbackMaxIndex: 9,
      playbackSpeed: 3,
      playbackMaxSpeed: 5,
      playbackSpeedMultiplier: 100, // speed * ms
      playbackSpeedWaitMultiplier: 3,
      playbackTimer: null,
      playbackToggle: false,
      refreshTimer: null,
      refreshInterval: Duration.fromMillis(300000), // 5min
      searchFilter: '',
      searchType: null,
      standardPath: '/standard',
      baseImagesPath: '/ridge',
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapGetters([
      'radarUrl'
    ]),
    currentImageUrl () {
      if (this.playbackAuto) {
        return this.autoImageUrl
      } else {
        return this.imageUrls ? this.imageUrls[this.playbackIndex] : null
      }
    },
    currentImageUrlWithRefresh () {
      let imageUpdated = null
      if (this.playbackAuto) {
        imageUpdated = this.imageAutoUpdatedAt
      } else {
        imageUpdated = this.imageUpdatedAt
      }
      return imageUpdated ? this.appendImageUrlRefresh(this.currentImageUrl, imageUpdated) : null
    },
    enhancedLabel () {
      if (this.locationType === 'station') {
        return `Enhanced ${this.locationId} Radar`
      } else {
        return 'Enhanced Radar'
      }
    },
    enhancedUrl () {
      if (this.locationType === 'station') {
        return '/?settings=v1_' + btoa(JSON.stringify({
          'agenda': {
            'id': 'local',
            'center': this.locationMetadata.rdrLonLat,
            'zoom': 7,
            'filter': 'WSR-88D',
            'layer': 'sr_bref',
            'station': this.locationId,
            'transparent': true,
            'alertsOverlay': true,
            'stationIconsOverlay': true
          },
          'animating': false,
          'base': 'standard',
          'county': false,
          'cwa': false,
          'state': false,
          'menu': true,
          'shortFusedOnly': true,
          'opacity': {
            'alerts': 0.8,
            'local': 0.6,
            'localStations': 0.8,
            'national': 0.6
          }
        }))
      } else if (this.locationType === 'region') {
        return '/?settings=v1_' + btoa(JSON.stringify({
          'agenda': {
            'id': 'national',
            'extent': this.locationMetadata.extent,
            'layer': 'bref_qcd'
          }
        }))
      }
      return ''
    },
    latestImageUrl () {
      return this.imageUrls ? this.imageUrls[this.playbackLatestIndex] : null
    },
    autoImageUrl () {
      return this.imageBaseUrl ? `${this.imageBaseUrl}_loop.gif` : null
    },
    imageBaseUrl () {
      let rootUrl = this.radarUrl ? `${this.radarUrl}` : ''
      let product = this.$route.params.product ? `${this.$route.params.product}` : 'standard'
      let imagePath = PRODUCTS[product] && PRODUCTS[product].path ? PRODUCTS[product].path : ''
      let locationId = this.locationId ? this.locationId.toUpperCase() : ''
      return `${rootUrl}${this.baseImagesPath}${this.standardPath}${imagePath}/${locationId}`
    },
    imageUrls () {
      if (this.imageBaseUrl) {
        let urls = []
        for (let x = 0; x <= 9; x++) {
          urls.push(`${this.imageBaseUrl}_${x}.gif`)
        }
        return urls
      }
      return null
    },
    stationLocations () {
      let locations = []
      for (let station in STATIONS) {
        let stationObj = STATIONS[station]
        locations.push({
          id: station,
          href: `/station/${station.toLowerCase()}/standard`,
          label: station,
          secondaryLabel: stationObj.wfoName,
          type: 'station'
        })
      }
      return locations.sort((a, b) => a.id.localeCompare(b.id))
    },
    regionLocations () {
      let locations = []
      for (let region in REGIONS) {
        let regionObj = REGIONS[region]
        locations.push({
          id: region,
          href: `/region/${region.toLowerCase()}/standard`,
          label: regionObj.name,
          abbvLabel: regionObj.abbvName,
          secondaryLabel: null,
          type: 'region',
          order: regionObj.order
        })
      }
      return locations.sort((a, b) => a.order - b.order)
    },
    filteredLocations () {
      let locations = this.searchType === 'station' ? this.stationLocations : this.regionLocations
      if (this.searchFilter && this.searchFilter.length > 0) {
        const filter = this.searchFilter.toLowerCase()
        return locations.filter(location => {
          let searchOption = location.label.toLowerCase()
          if (location.secondaryLabel) {
            searchOption += `+${location.secondaryLabel.toLowerCase()}`
          }
          if (location.abbvLabel) {
            searchOption += `+${location.abbvLabel.toLowerCase()}`
          }
          if (searchOption.indexOf(filter) >= 0) {
            return true
          }
          return false
        })
      }
      return locations
    },
    productName () {
      return this.$route.params.product && PRODUCTS[this.$route.params.product]
        ? PRODUCTS[this.$route.params.product].longText
        : PRODUCTS[Object.keys(PRODUCTS)[0]].longText
    },
    latestImageLabel () {
      return this.windowWidth > 540 ? this.latestImageUrl : `${this.locationId}_${this.playbackLatestIndex}.gif`
    },
    autoImageLabel () {
      return this.windowWidth > 540 ? this.autoImageUrl : `${this.locationId.toUpperCase()}_loop.gif`
    },
    imageUpdatedLabel () {
      return this.playbackAuto ? this.imageAutoUpdatedAt : this.imageUpdatedAt
    },
    locationPanelMetadata () {
      if (this.locationMetadata) {
        if (this.locationType === 'station') {
          return {
            title: `Local Station ${this.locationLabel}`,
            secondaryTitle: this.locationMetadata.wfoName
          }
        } else if (this.locationType === 'region') {
          return {
            title: `Region ${this.locationLabel}`
          }
        }
      }
      return null
    },
    locationLabel () {
      if (this.locationMetadata) {
        if (this.windowWidth < 470 && this.locationMetadata.abbvName) {
          return this.locationMetadata.abbvName
        } else if (this.locationMetadata.name) {
          return this.locationMetadata.name
        }
        return this.locationId
      }
      return 'Select Location'
    },
    officeLabel () {
      if (this.locationMetadata && this.locationMetadata.wfoId && this.locationMetadata.wfoName) {
        return `${this.locationMetadata.wfoId} - ${this.locationMetadata.wfoName}`
      }
      return null
    },
    officeUrl () {
      if (this.locationMetadata && this.locationMetadata.wfoId) {
        return `https://weather.gov/${this.locationMetadata.wfoId.toLowerCase()}`
      }
      return null
    },
    locationNeighbors () {
      if (this.locationMetadata && this.locationMetadata.neighbors) {
        return this.locationMetadata.neighbors
      }
      return null
    },
    viewPanelFullSize () {
      return this.locationId === null
    },
    isRegionRoute () {
      return this.$route.path.includes('/region/')
    }
  },
  watch: {
    locationId: {
      immediate: true,
      handler () {
        if (this.locationId === null) {
          this.activePanel = 'location'
        } else {
          this.activePanel = null
        }
      }
    },
    locationType: {
      immediate: true,
      handler () {
        this.searchType = this.locationType
      }
    },
    $route: {
      immediate: true,
      handler (to, from) {
        this.onMapLoading()
        if (to && to.params && to.params.id) {
          if (to.path && to.path.indexOf('station') > 0) {
            this.changeLocation(to.params.id, 'station')
          } else if (to.path && to.path.indexOf('region') > 0) {
            this.changeLocation(to.params.id, 'region')
          } else {
            this.changeLocation(to.params.id)
          }
        } else {
          this.changeLocation()
        }
        this.activePanel = null
      }
    }
  },
  mounted () {
    // used for a media queries effect
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions([
      'tracker'
    ]),
    appendImageUrlRefresh (url, refresh) {
      // parameter used to force browser refresh, will be ignored by CDN
      const refreshed = refresh ? refresh.toMillis() : 'now'
      return `${url}?refreshed=${refreshed}`
    },
    changeLocation (id, type) {
      this.imageAutoUpdatedAt = null
      this.imageUpdatedAt = null

      if (id && id.length > 0) {
        // Some location ids are shared between stations and regions
        // If currently viewing a region, don't look for a station
        if ((type === 'station' || !type) && STATIONS[id.toUpperCase()]) {
          this.locationType = 'station'
          this.locationId = id.toUpperCase()
          this.locationMetadata = STATIONS[this.locationId]
        } else if ((type === 'region' || !type) && REGIONS[id]) {
          this.locationType = 'region'
          this.locationId = id
          this.locationMetadata = REGIONS[this.locationId]
        } else {
          console.log('Invalid location provided: ', id)
          this.locationType = null
          this.locationId = null
          this.locationMetadata = null
        }
      }

      if (this.locationId) {
        console.log(`Location set to (${this.locationType}) ${this.locationId}`)
        this.refreshImages()
      } else {
        console.log('Using default location')
        this.$router.replace(`/region/conus/standard`)
      }
    },
    changePlaybackSpeed (delta) {
      if ((delta < 0 && this.playbackSpeed > 1) ||
          (delta > 0 && this.playbackSpeed < this.playbackMaxSpeed)) {
        this.playbackSpeed = this.playbackSpeed + delta
        this.standardTracker('speed', this.playbackSpeed)
      }
    },
    changePlaybackIndex (delta) {
      if (this.playbackIndex + delta < 0) {
        this.playbackIndex = this.playbackMaxIndex
      } else if (this.playbackIndex + delta > this.playbackMaxIndex) {
        this.playbackIndex = 0
      } else {
        this.playbackIndex += delta
      }
    },
    autoPlayback () {
      this.cancelPlayback()
      this.playbackAuto = !this.playbackAuto
      this.standardTracker('auto', this.playbackAuto ? 'enabled' : 'disabled')
      this.refreshImages()
    },
    cancelPlayback () {
      if (this.playbackToggle) {
        this.togglePlayback()
      }
    },
    backPlayback () {
      this.cancelPlayback()
      // index goes backwards through time, so increase by 1
      this.changePlaybackIndex(1)
    },
    forwardPlayback () {
      this.cancelPlayback()
      // index goes backwards through time, so decrease by 1
      this.changePlaybackIndex(-1)
    },
    loopPlayback () {
      this.changePlaybackIndex(-1)
      if (this.playbackToggle) {
        let delay = (this.playbackMaxSpeed - this.playbackSpeed + 1) * this.playbackSpeedMultiplier
        if (this.playbackIndex === this.playbackLatestIndex) {
          delay = delay * this.playbackSpeedWaitMultiplier
        }
        this.playbackTimer = setTimeout(this.loopPlayback.bind(this), delay)
      }
    },
    onMapLoading () {
      this.mapLoading = 1
      setTimeout(function () {
        if (this.mapLoading === 1) {
          this.mapLoading = 2
        }
      }.bind(this), 1000)
    },
    onMapLoad () {
      this.mapLoading = 0
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    refreshImages () {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer)
      }

      let nextIntervalCheck = null
      if (this.playbackAuto && this.autoImageUrl) {
        if (!this.imageAutoUpdatedAt) {
          console.log('Refresh auto image on load')
          this.imageAutoUpdatedAt = DateTime.fromMillis(0)
        }
        let autoDiff = Math.abs(this.imageAutoUpdatedAt.diffNow().toMillis())
        if (autoDiff > this.refreshInterval.toMillis()) {
          console.log('Refreshing auto image')
          this.imageAutoUpdatedAt = DateTime.now()
        } else {
          nextIntervalCheck = this.refreshInterval.toMillis() - autoDiff
          console.log('Skipping refresh for auto image')
        }
      } else if (this.imageUrls) {
        if (!this.imageUpdatedAt) {
          console.log('Refresh image on load')
          this.imageUpdatedAt = DateTime.fromMillis(0)
        }
        let diff = Math.abs(this.imageUpdatedAt.diffNow().toMillis())
        if (diff > this.refreshInterval.toMillis()) {
          console.log('Refreshing images')
          this.imageUpdatedAt = DateTime.now()
          for (let x in this.imageUrls) {
            const image = new Image()
            image.src = this.appendImageUrlRefresh(this.imageUrls[x], this.imageUpdatedAt)
          }
        } else {
          nextIntervalCheck = this.refreshInterval.toMillis() - diff
          console.log('Skipping refresh for images')
        }
      }
      if (!nextIntervalCheck) {
        nextIntervalCheck = this.refreshInterval.toMillis()
      }
      console.log(`Next refresh in ${nextIntervalCheck} milliseconds`)
      if (nextIntervalCheck >= 0) {
        this.refreshTimer = setTimeout(this.refreshImages.bind(this), nextIntervalCheck)
      } else {
        console.log(`Invalid refresh interval: ${nextIntervalCheck}`)
      }
    },
    closePanel () {
      this.activePanel = null
    },
    selectLocation (locationId) {
      this.standardTracker('location', locationId)
      this.closePanel()
    },
    standardTracker (action, value) {
      this.tracker({
        category: 'radar-standard',
        type: 'event',
        options: {
          action: action,
          label: value
        }
      })
    },
    togglePan () {
      this.panToggle = !this.panToggle
      this.standardTracker('pan', this.panToggle ? 'enabled' : 'disabled')
    },
    togglePanel (panel) {
      if (panel === 'location') {
        this.searchFilter = ''
      }
      if (this.activePanel !== panel) {
        this.activePanel = panel
        this.standardTracker('panel', panel)
      } else {
        this.activePanel = null
      }
    },
    togglePlayback () {
      this.playbackToggle = !this.playbackToggle
      if (this.playbackToggle) {
        this.loopPlayback()
      } else {
        clearTimeout(this.playbackTimer)
      }
      this.standardTracker('playback', this.playbackToggle ? 'start' : 'pause')
    },
    toggleSize () {
      if (this.locationId.indexOf('-large') > 0) {
        const locationId = this.locationId.split('-')[0]
        this.$router.replace(`/region/${locationId}/standard`)
      } else {
        this.$router.replace(`/region/${this.locationId}-large/standard`)
      }
    },
    getProducts () {
      let newProducts = []
      for (let product in PRODUCTS) {
        let productObj = PRODUCTS[product]
        let href = product === 'standard'
          ? `/station/${this.locationId}/standard`
          : `/station/${this.locationId}/${product}/standard`
        newProducts.push({
          name: product,
          longText: productObj.longText,
          href: href
        })
      }
      return newProducts
    }
  }
}
</script>

<style scoped>
.page {
  max-width: 100%;
}

.container {
  width: fit-content;
}

.menu {
  color: #fff;
  background: #000;
  display: flex;
  font-size: 20px;
  padding: 5px 4px 3px;
  align-items: center;
  height: 40px;
}

.controls {
  color: #fff;
  background: #333;
  display: flex;
  font-size: 20px;
  padding: 5px 4px 3px;
  align-items: center;
  height: 40px;
}

.featureButtonExpand {
  flex: 1 0 auto;
}

.featureButtonFixed {
  flex: none;
  text-align: center;
}

.featureValue {
  display: block;
  cursor: pointer;
  margin: 4px 2px;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
}

.featureValueFixed {
  flex: none;
  text-align: center;
}

.featureButton {
  display: block;
  cursor: pointer;
  margin: 4px 8px;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  min-width: 34px;
}

.controlFeature-auto > .controlFeature-autoIcon {
  transform: rotate(180deg);
  color: #fff;
}

.controlFeature-auto > .controlFeature-autoEnabled {
  transform: rotate(0);
  color: rgb(96, 231, 96);
}

.controlFeature-speeddown {
  text-align: right;
}

.controlFeature-speedup {
  text-align: left;
}

.controlFeature-speedStep {
  display: inline-block;
  width: 7px;
  height: 100%;
  background: #aaa;
  margin-right: 2px;
}

.controlFeature-speedStep:last-of-type {
  margin-right: 0;
}

.controlFeature-speedStep-active {
  background: rgb(96, 231, 96);
}

.controlFeature-frameNumber {
  font-size: 18px;
}

.map {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 300px;
}

.mapImage {
  max-width: 100%;
  display: block;
}

.mapInvalidImage {
  min-width: 360px;
  height: 500px;
  background: #b7f8ef;
  padding: 50px;
}

.mapOverlays,
.mapOverlay-pan {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.mapMessage {
  background: #fff;
  border-bottom: 2px solid #000;
  padding: 20px;
}

.mapPanButton {
  width: 40px;
  height: 40px;
  color: #000;
  font-weight: bold;
  position: absolute;
}

.mapPanButton > * {
  font-size: 40px;
}

.mapPanButton::before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 20px;
}

.mapPanButton-NW {
  top: 7%;
  left: 3%;
}

.mapPanButton-NW > * {
  transform: rotate(-45deg);
}

.mapPanButton-N {
  top: 7%;
  left: 50%;
  transform: translate(-50%, 0);
}

.mapPanButton-N > * {
  transform: rotate(0);
}

.mapPanButton-NE {
  top: 7%;
  right: 3%;
}

.mapPanButton-NE > * {
  transform: rotate(45deg);
}

.mapPanButton-E {
  top: 50%;
  transform: translate(0, -50%);
  right: 3%;
}

.mapPanButton-E > * {
  transform: rotate(90deg);
}

.mapPanButton-SE {
  bottom: 7%;
  right: 3%;
}

.mapPanButton-SE > * {
  transform: rotate(135deg);
}

.mapPanButton-S {
  bottom: 7%;
  left: 50%;
  transform: translate(-50%, 0);
}

.mapPanButton-S > * {
  transform: rotate(180deg);
}

.mapPanButton-SW {
  bottom: 7%;
  left: 3%;
}

.mapPanButton-SW > * {
  transform: rotate(-135deg);
}

.mapPanButton-W {
  top: 50%;
  transform: translate(0, -50%);
  left: 3%;
}

.mapPanButton-W > * {
  transform: rotate(-90deg);
}

.mapOverlay-panels {
  position: relative;
  height: 100%;
}

.mapPanel {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  min-width: 260px;
  height: 100%;
  overflow: hidden;
}

.mapPanelContent {
  background: #efefef;
  padding: 20px 16px;
  overflow-y: auto;
  max-height: calc(100% - 48px);
}

.mapPanelFullSize {
  width: 100%;
}

.mapPanelTitle {
  font-size: 120%;
}

.mapPanel-product li {
  margin-bottom: 12px;
}

.mapPanel-locationSearchType {
  margin-top: 5px;
}

.mapPanel-locationSearchType > input {
  transform: scale(1.5);
  margin-right: 10px;
}

.mapPanel-locationSearchType > span {
  margin-right: 10px;
}

.mapPanel-locationResults {
  padding: 20px 0;
}

.mapPanel-location input[type="text"] {
  width: 100%;
  font-size: 18px;
  padding: 6px 10px;
}

.mapPanel ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 12px;
  list-style: none;
}

.mapPanel ul > li {
  padding-top: 7px;
}

.mapPanel ul > li:first-of-type {
  padding-top: 0;
}

.mapPanel a,
.mapPanel a:hover,
.mapPanel a:visited,
.mapPanel a:active {
  color: blue;
}

.info {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0;
  box-sizing: border-box;
  max-width: 550px;
}

.infoLabel {
  padding: 5px;
  white-space: nowrap;
}

.infoValue {
  padding: 5px;
}

.urlList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.urlListItem {
  margin-left: 0;
  padding-bottom: 4px;
}

.productBar {
  padding: 8px 10px;
  background: #fff;
  border-bottom: 2px solid;
  font-size: 14px;
  cursor: pointer;
}

.productArrow svg {
  transition: transform 0.3s ease-in-out;
  transform: rotate(0deg);
}

.productRotateArrow svg {
  transition: transform 0.3s ease-in-out;
  transform: rotate(180deg);
}

.mapPanel-product ul {
  padding-left: 0;
}

.mapPanelActionBar {
  padding: 10px 20px 10px 10px;
  background: #dedede;
}

.mapPanelActionBar input {
  margin: 0;
  padding: 5px 10px;
  border: 1px solid #a5a5a5;
  background-color: #eee;
}
</style>
