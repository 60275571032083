<template>
  <article>
    <div class="content">
      <div class="site-search">
        <form
          method="get"
          action="//search.usa.gov/search"
        >
          <input
            type="hidden"
            name="v:project"
            value="firstgov"
          >
          Search:
          <input
            id="query"
            type="text"
            name="query"
            size="18"
          >
          <select id="search-scope">
            <option value="weather.gov">
              NWS
            </option>
            <option value="noaa.gov">
              All NOAA
            </option>
          </select>
          <input
            id="search-submit"
            type="submit"
            value="Go"
          >
        </form>
      </div>
      <template-menu-list
        :items="sitemap"
      />
    </div>
  </article>
</template>

<script>
import { getConfig } from '../../config'

export default {
  data () {
    return {
      sitemap: [
        {
          text: 'Forecast',
          href: `${this.weatherUrl}/forecastmaps`,
          items: [
            {
              text: 'Local',
              href: this.weatherUrl
            },
            {
              text: 'Graphical',
              href: 'http://digital.weather.gov'
            },
            {
              text: 'Aviation',
              href: 'http://www.aviationweather.gov'
            },
            {
              text: 'Marine',
              href: 'http://www.nws.noaa.gov/om/marine/home.htm'
            },
            {
              text: 'Rivers and Lakes',
              href: 'http://water.weather.gov/ahps'
            },
            {
              text: 'Hurricanes',
              href: 'http://www.nhc.noaa.gov'
            },
            {
              text: 'Severe Weather',
              href: 'http://www.spc.noaa.gov'
            },
            {
              text: 'Fire Weather',
              href: 'http://www.srh.noaa.gov/ridge2/fire/'
            },
            {
              text: 'Sun/Moon',
              href: 'http://aa.usno.navy.mil/data/docs/RS_OneDay.php'
            },
            {
              text: 'Long Range Forecasts',
              href: 'http://www.cpc.ncep.noaa.gov'
            },
            {
              text: 'Climate Predictions',
              href: 'http://www.cpc.ncep.noaa.gov'
            }
          ]
        },
        {
          text: 'Past Weather',
          href: 'http://www.nws.noaa.gov/climate',
          items: [
            {
              text: 'Past Weather',
              href: 'http://www.nws.noaa.gov/climate'
            },
            {
              text: 'Heating/Cooling Days',
              href: 'http://www.nws.noaa.gov/climate'
            },
            {
              text: 'Monthly Temperatures',
              href: 'http://www.nws.noaa.gov/climate'
            },
            {
              text: 'Records',
              href: 'http://www.nws.noaa.gov/climate'
            },
            {
              text: 'Astronomical Data',
              href: 'http://aa.usno.navy.mil'
            }
          ]
        },
        {
          text: 'Safety',
          href: `${this.weatherUrl}/safety`,
          items: [
            {
              text: 'Floods',
              href: 'http://www.floodsafety.noaa.gov'
            },
            {
              text: 'Tsunami',
              href: 'http://www.nws.noaa.gov/om/Tsunami/index.html'
            },
            {
              text: 'Beach Hazards',
              href: 'http://www.ripcurrents.noaa.gov/beach_hazards.shtml'
            },
            {
              text: 'Wildfire',
              href: 'http://www.nws.noaa.gov/om/fire'
            },
            {
              text: 'Cold',
              href: `${this.weatherUrl}/cold`
            },
            {
              text: 'Tornados',
              href: `${this.weatherUrl}/tornado`
            },
            {
              text: 'Fog',
              href: 'http://www.nws.noaa.gov/om/fog'
            },
            {
              text: 'Air Quality',
              href: 'http://www.nws.noaa.gov/airquality'
            },
            {
              text: 'Heat',
              href: 'http://www.nws.noaa.gov/om/heat/index.shtml'
            },
            {
              text: 'Hurricanes',
              href: 'http://www.nws.noaa.gov/om/hurricane/index.shtml'
            },
            {
              text: 'Lightning',
              href: 'http://www.lightningsafety.noaa.gov'
            },
            {
              text: 'Safe Boating',
              href: 'http://www.nws.noaa.gov/os/marine/safeboating'
            },
            {
              text: 'Rip Currents',
              href: 'http://www.ripcurrents.noaa.gov'
            },
            {
              text: 'Thunderstorms and Tornados',
              href: 'http://www.nws.noaa.gov/os/thunderstorm'
            },
            {
              text: 'Space Weather',
              href: 'http://www.nws.noaa.gov/om/space'
            },
            {
              text: 'Sun (Ultraviolet Radiation)',
              href: 'http://www.nws.noaa.gov/om/heat/uv.shtml'
            },
            {
              text: 'Safety Campaigns',
              href: `${this.weatherUrl}/safetycampaign`
            },
            {
              text: 'Wind',
              href: 'http://www.nws.noaa.gov/om/wind'
            },
            {
              text: 'Drought',
              href: 'http://www.nws.noaa.gov/om/drought'
            },
            {
              text: 'Winter Weather',
              href: 'http://www.nws.noaa.gov/om/winter/index.shtml'
            }
          ]
        },
        {
          text: 'Information',
          href: `${this.weatherUrl}/informationcenter`,
          items: [
            {
              text: 'Owlie\'s Kids Page',
              href: `${this.weatherUrl}/owlie`
            },
            {
              text: 'Wireless Emergency Alerts',
              href: 'http://www.nws.noaa.gov/com/weatherreadynation/wea.html'
            },
            {
              text: 'Daily Briefing',
              href: `${this.weatherUrl}/briefing`
            },
            {
              text: 'Forecast Models',
              href: 'http://mag.ncep.noaa.gov'
            },
            {
              text: 'GIS Data Portal',
              href: 'http://www.nws.noaa.gov/gis'
            },
            {
              text: 'Cooperative Observers',
              href: 'http://www.nws.noaa.gov/om/coop'
            },
            {
              text: 'Publications',
              href: `${this.weatherUrl}/publications`
            },
            {
              text: 'SKYWARN Storm Spotters',
              href: 'http://www.nws.noaa.gov/skywarn'
            },
            {
              text: 'Weather-Ready Nation',
              href: 'http://www.nws.noaa.gov/com/weatherreadynation'
            },
            {
              text: 'StormReady',
              href: 'http://www.nws.noaa.gov/stormready'
            },
            {
              text: 'NOAA Weather Radio',
              href: 'http://www.nws.noaa.gov/nwr'
            },
            {
              text: 'Brochures',
              href: 'http://www.nws.noaa.gov/om/brochures.shtml'
            },
            {
              text: 'Damage/Fatality/Injury Statistics',
              href: 'http://www.nws.noaa.gov/om/hazstats.shtml'
            },
            {
              text: 'TsunamiReady',
              href: 'http://www.tsunamiready.noaa.gov'
            }
          ]
        },
        {
          text: 'Education',
          href: `${this.weatherUrl}/owlie`,
          items: [
            {
              text: 'Be A Force of Nature',
              href: 'http://www.nws.noaa.gov/com/weatherreadynation/force.html'
            },
            {
              text: 'NWS Education Home',
              href: `${this.weatherUrl}/owlie`
            }
          ]
        },
        {
          text: 'News',
          href: `${this.weatherUrl}/contact-media/`,
          items: [
            {
              text: 'NWS News',
              href: `${this.weatherUrl}/news`
            },
            {
              text: 'Events',
              href: 'http://www.nws.noaa.gov/com/weatherreadynation/calendar.html'
            },
            {
              text: 'Social Media',
              href: `${this.weatherUrl}/socialmedia`
            },
            {
              text: 'Pubs/Brochures/Booklets',
              href: 'http://www.nws.noaa.gov/om/brochures.shtml'
            },
            {
              text: 'NWS Media Contacts',
              href: 'http://www.nws.noaa.gov/pa/nws_contacts.php'
            }
          ]
        },
        {
          text: 'About',
          href: `${this.weatherUrl}/about`,
          items: [
            {
              text: 'About NWS',
              href: `${this.weatherUrl}/about`
            },
            {
              text: 'Organization',
              href: `${this.weatherUrl}/organization`
            },
            {
              text: 'Strategic Plan',
              href: 'http://www.nws.noaa.gov/sp'
            },
            {
              text: 'For NWS Employees',
              href: 'https://sites.google.com/a/noaa.gov/nws-best-practices/'
            },
            {
              text: 'National Centers',
              href: `${this.weatherUrl}/organization`
            },
            {
              text: 'Products and Services',
              href: 'http://www.nws.noaa.gov/tg'
            },
            {
              text: 'Contact Us',
              href: `${this.weatherUrl}/contact`
            },
            {
              text: 'Glossary',
              href: 'http://www.nws.noaa.gov/glossary'
            }
          ]
        }
      ]
    }
  },
  computed: {
    weatherUrl () {
      return getConfig().weatherUrl
    }
  }
}
</script>

<style scoped>
.content {
  padding: 20px;
}

.content h1 {
  font-size: 100%;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

.content p:first-child {
  padding-top: 0;
  margin-top: 0;
}

.site-search input[type=text] {
  height: 2em;
}

.site-search select {
  height: 2.4em;
}

.site-search input[type=submit] {
  height: 2.4em;
}
</style>
