import deepmerge from 'deepmerge'

import * as components from './components'
import { setConfig } from './config'
import { getLogger } from '../log'
import { SchemaValidator } from '../schema'
import fallback from '../fallback'

const COMPONENTS = {
  banner: components.Banner,
  button: components.Button,
  menu: components.Menu,
  'menu-list': components.MenuList,
  page: components.Page,
  panel: components.Panel,
  'panel-explore': components.PanelExplore,
  'panel-help': components.PanelHelp,
  'panel-local': components.PanelLocal
}

const DEFAULTS = {
  componentPrefix: 'template-',
  instanceName: '$template',
  weatherUrl: 'https://weather.gov'
}

const CONFIG_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  $id: '/config',
  type: 'object',
  additionalProperties: false,
  required: ['componentPrefix', 'instanceName', 'localRoutes', 'weatherUrl'],
  properties: {
    componentPrefix: { type: 'string' },
    instanceName: { type: 'string' },
    localRoutes: {
      type: 'array',
      items: { $ref: '#/definitions/route' }
    },
    weatherUrl: { type: 'string' }
  },
  definitions: {
    route: {
      type: 'object',
      additionalProperties: false,
      required: ['href', 'order', 'text'],
      properties: {
        href: { type: 'string' },
        order: { type: 'number' },
        text: { type: 'string' },
        items: {
          type: 'array',
          items: { $ref: '#/definitions/route' }
        }
      }
    }
  }
}

function plugin (Vue, options = {}) {
  options = deepmerge(DEFAULTS, options)

  const validator = new SchemaValidator({ schemas: [CONFIG_SCHEMA] })
  validator.validate('/config', options)

  setConfig({
    localRoutes: options.localRoutes,
    logger: getLogger(),
    weatherUrl: options.weatherUrl
  })

  Vue.use(fallback)

  for (const key of Object.keys(COMPONENTS)) {
    // eslint-disable-next-line security/detect-object-injection
    Vue.component(`${options.componentPrefix}${key}`, COMPONENTS[key])
  }

  getLogger().debug(
    'Template initialized:' +
    `\n  Instance name: ${options.instanceName}` +
    `\n  Component prefix: ${options.componentPrefix}` +
    `\n  URL for weather.gov site: ${options.weatherUrl}` +
    `\n  Local routes (top level): ${options.localRoutes.length}`
  )
}

export default plugin

export {
  components,
  DEFAULTS
}
