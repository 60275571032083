import deepmerge from 'deepmerge'

import { LEVELS, Logger, disable, getLogger, getLoggers, getDefaultLevel, setDefaultLevel } from './log'
import { SchemaValidator } from '../schema'

const DEFAULTS = {
  defaultLevel: 'DEBUG',
  instanceName: '$log',
  logEvents: true
}

const CONFIG_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  $id: '/config',
  type: 'object',
  additionalProperties: false,
  required: ['instanceName'],
  properties: {
    defaultLevel: { type: 'string', enum: ['DEBUG', 'INFO', 'WARNING', 'ERROR'] },
    disabled: { type: 'boolean' },
    instanceName: { type: 'string' },
    logEvents: { type: 'boolean' }
  }
}

function plugin (Vue, options = {}) {
  options = deepmerge(DEFAULTS, options)

  const validator = new SchemaValidator({ schemas: [CONFIG_SCHEMA] })
  validator.validate('/config', options)

  if (options.hasOwnProperty('defaultLevel')) {
    setDefaultLevel(options.defaultLevel)
  }

  if (options.hasOwnProperty('disabled') && options.disabled) {
    disable()
  }

  const logger = getLogger()

  Vue.prototype[options.instanceName] = {
    get defaultLevel () {
      return getDefaultLevel()
    },
    set defaultLevel (level) {
      setDefaultLevel(level)
    },
    getLogger: getLogger,
    levels: LEVELS,
    debug: logger.debug,
    info: logger.info,
    warning: logger.warning,
    error: logger.error
  }

  if (options.logEvents) {
    const eventLogger = getLogger('event')
    const emit = Vue.prototype.$emit
    Vue.prototype.$emit = function (...args) {
      let message = [`Event "${args[0]}"`]
      if (this.$vnode) {
        message.push(` from ${this.$vnode.tag}`)
      }
      if (args.length > 1) {
        message.push(': ')
      }
      eventLogger.debug(message.join(''), ...args.slice(1))
      emit.apply(this, args)
    }
  }

  logger.debug(
    'Logging plugin initialized:' +
    `\n  Instance name: ${options.instanceName}` +
    `\n  Default logging level: ${Object.keys(LEVELS).find(key => LEVELS[key] === getDefaultLevel())}` +
    `\n  Vue event logging: ${options.logEvents ? 'enabled' : 'disabled'}`
  )
}

export default plugin

export {
  Logger,
  getLogger,
  getLoggers,
  getDefaultLevel,
  setDefaultLevel,
  DEFAULTS,
  LEVELS
}
