<template>
  <li class="treelist-item">
    <a
      v-if="items && items.length > 0"
      @click="toggleOpen"
    >
      {{ label }}
      <font-awesome-icon
        icon="chevron-down"
        :class="{ 'treelist-item-icon-open': isOpen }"
        class="treelist-item-icon"
      />
    </a>
    <router-link
      v-else-if="href && href.length > 0 && href.charAt(0) === '/'"
      :class="{'router-link-active': isActive(this)}"
      :to="href"
      exact
    >
      {{ label }}
    </router-link>
    <a
      v-else
      :href="href"
    >
      {{ label }}
    </a>
    <template-menu-list
      v-if="items && items.length > 0"
      :is-open="isOpen"
      :items="items"
      :depth="depth"
    />
  </li>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faChevronDown)

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    items: {
      default: () => [],
      type: Array
    },
    depth: {
      default: 0,
      type: Number
    },
    href: {
      default: '',
      type: String
    },
    label: {
      default: 'No Label',
      type: String
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    // this is a hack until the vue router check for active works with aliases (aka VueRouter 4/Vue 3)
    isActive (vulement) {
      // no need to calculate if exists
      /*
      if(vulement && vulement.$el && vulement.$el.classList.contains('router-link-active')) {
        return true
      }
      */
      // this condition is limited to matching an alias to a simplified route
      if (this.$route.matched && this.$route.matched.length > 0 && this.$route.matched[0].regex.test(this.href)) {
        return true
      }
      return false
    },
    toggleOpen () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped>
li.treelist-item {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

li.treelist-item a {
  display: inline-block;
  margin: 1px 0;
  padding: 7px 40px 7px 10px;
  border-left: 10px solid #fff;
  text-decoration: none;
  color: #0a4595;
  cursor: pointer;
  vertical-align: middle;
}

li.treelist-item a.router-link-exact-active,
li.treelist-item a.router-link-active {
  border-left: 10px solid #0099d8;
  font-weight: bold;
  color: black;
  cursor: default;
}

li.treelist-item a:not(.router-link-exact-active):hover {
  border-left: 10px solid #efefef;
  background: #efefef;
}

li.treelist-item .treelist-item-icon {
  margin-left: 5px;
  vertical-align: middle;
  transition: transform 500ms ease;
}

li.treelist-item .treelist-item-icon-open {
  transform: rotate(180deg);
}
</style>
