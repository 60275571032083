import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export function createStore (state) {
  /* cmiRadarUrl allows development to point to production for radar standard (lite) images
     This env is unnecessary in production which will use local paths if not provided. */
  return new Vuex.Store({
    state: {
      alertsUrl: state.alertsUrl,
      apiUrl: state.apiUrl,
      cmiRadarUrl: state.cmiRadarUrl,
      forecastUrl: state.forecastUrl,
      gisUrl: state.gisUrl,
      radarUrl: state.radarUrl,
      weatherUrl: state.weatherUrl
    },
    actions: {
      tracker ({ commit }, input) {
        if (Vue.$gtag) {
          let meta = {
            event_category: input.category || 'radar-map'
          }
          if (input.type === 'event') {
            if (input.options.label) {
              meta.event_label = input.options.label
            }
            if (input.options.value) {
              meta.value = input.options.value
            }
            Vue.$gtag.event(input.options.action, meta)
          }
        }
      }
    },
    getters: {
      alertsUrl: state => state.alertsUrl,
      apiUrl: state => state.apiUrl,
      cmiRadarUrl: state => state.cmiRadarUrl,
      forecastUrl: state => state.forecastUrl,
      gisUrl: state => state.gisUrl,
      radarUrl: state => state.radarUrl,
      weatherUrl: state => state.weatherUrl
    }
  })
}
