export const REGIONS = {
  'alaska': {
    extent: [],
    name: 'Alaska',
    neighbors: {
      'se': 'pacnorthwest',
      'w': 'guam',
      'sw': 'hawaii'
    },
    order: 101
  },
  'pacnorthwest': {
    extent: [],
    name: 'Pacific Northwest',
    abbvName: 'Pac. Northwest',
    neighbors: {
      'e': 'northrockies',
      'nw': 'alaska',
      's': 'pacsouthwest',
      'se': 'southrockies'
    },
    order: 10
  },
  'northrockies': {
    extent: [],
    name: 'North Rockies',
    abbvName: 'Nrn. Rockies',
    neighbors: {
      'e': 'uppermissvly',
      'se': 'southplains',
      's': 'southrockies',
      'sw': 'pacsouthwest',
      'w': 'pacnorthwest'
    },
    order: 11
  },
  'uppermissvly': {
    extent: [],
    name: 'Upper Mississippi Valley',
    abbvName: 'Upper Miss. Vly.',
    neighbors: {
      'e': 'centgrlakes',
      'se': 'southeast',
      's': 'southmissvly',
      'sw': 'southplains',
      'w': 'northrockies'
    },
    order: 12
  },
  'centgrlakes': {
    extent: [],
    name: 'Central Great Lakes',
    abbvName: 'Great Lakes',
    neighbors: {
      'e': 'northeast',
      's': 'southeast',
      'sw': 'southplains',
      'w': 'uppermissvly'
    },
    order: 13
  },
  'northeast': {
    extent: [],
    name: 'Northeast',
    neighbors: {
      's': 'tjua',
      'sw': 'southeast',
      'w': 'centgrlakes'
    },
    order: 14
  },
  'hawaii': {
    extent: [],
    name: 'Hawaii',
    neighbors: {
      'ne': 'alaska',
      'e': 'pacsouthwest',
      'nw': 'guam'
    },
    order: 102
  },
  'pacsouthwest': {
    extent: [],
    name: 'Pacific Southwest',
    abbvName: 'Pac. Southwest',
    neighbors: {
      'n': 'pacnorthwest',
      'ne': 'northrockies',
      'e': 'southrockies',
      'w': 'hawaii'
    },
    order: 20
  },
  'southrockies': {
    extent: [],
    name: 'Southern Rockies',
    abbvName: 'Srn. Rockies',
    neighbors: {
      'n': 'northrockies',
      'ne': 'uppermissvly',
      'e': 'southplains',
      'w': 'pacsouthwest',
      'nw': 'pacnorthwest'
    },
    order: 21
  },
  'southplains': {
    extent: [],
    name: 'Southern Plains',
    abbvName: 'Srn. Plains',
    neighbors: {
      'ne': 'uppermissvly',
      'e': 'southeast',
      'w': 'southrockies',
      'nw': 'northrockies'
    },
    order: 22
  },
  'southmissvly': {
    extent: [],
    name: 'Southern Mississippi Valley',
    abbvName: 'Srn. Miss. Vly.',
    neighbors: {
      'n': 'uppermissvly',
      'ne': 'centgrlakes',
      'e': 'southeast',
      'w': 'southplains',
      'nw': 'northrockies'
    },
    order: 23
  },
  'southeast': {
    extent: [],
    name: 'Southeast',
    neighbors: {
      'n': 'centgrlakes',
      'ne': 'northeast',
      'se': 'tjua',
      'w': 'southmissvly',
      'nw': 'uppermissvly'
    },
    order: 24
  },
  'guam': {
    extent: [],
    name: 'Guam',
    neighbors: {
      'e': 'alaska',
      'se': 'hawaii'
    },
    order: 103
  },
  'tjua': {
    extent: [],
    name: 'Puerto Rico',
    neighbors: {
      'n': 'northeast',
      'nw': 'southeast'
    },
    order: 104
  },
  'conus': {
    extent: [],
    name: 'National',
    neighbors: {
      'nw': 'alaska',
      'w': 'guam',
      'sw': 'hawaii',
      'se': 'tjua'
    },
    order: 100
  },
  'conus-large': {
    extent: [],
    name: 'National (Large)',
    neighbors: {
      'nw': 'alaska',
      'w': 'guam',
      'sw': 'hawaii',
      'se': 'tjua'
    },
    order: 99
  }
}

export const PRODUCTS = {
  'reflectivity': {
    path: '',
    longText: 'Super Resolution Base Reflectivity'
  },
  'velocity': {
    path: '/base_velocity',
    longText: 'Super Resolution Base Velocity'
  }
}

export const STATIONS = {
  'KABR': {
    'rdrName': 'Aberdeen, SD',
    'rdrLonLat': [
      -98.41310,
      45.45580
    ],
    'wfoId': 'ABR',
    'wfoName': 'Aberdeen, SD',
    'neighbors': {
      'nw': 'KBIS',
      'n': 'KMBX',
      'ne': 'KMVX',
      'w': 'KUDX',
      'e': 'KMPX',
      'sw': 'KCYS',
      's': 'KLNX',
      'se': 'KFSD'
    }
  },
  'KABX': {
    'rdrName': 'Albuquerque, NM',
    'rdrLonLat': [
      -106.823,
      35.149
    ],
    'wfoId': 'ABQ',
    'wfoName': 'Albuquerque, NM',
    'neighbors': {
      'nw': 'KICX',
      'n': 'KGJX',
      'ne': 'KPUX',
      'w': 'KFSX',
      'e': 'KFDX',
      'sw': 'KEMX',
      's': 'KHDX',
      'se': 'KMAF'
    }
  },
  'KAKQ': {
    'rdrName': 'Wakefield, VA',
    'rdrLonLat': [
      -77.007,
      36.9838
    ],
    'wfoId': 'AKQ',
    'wfoName': 'Wakefield, VA',
    'neighbors': {
      'nw': 'KRLX',
      'n': 'KLWX',
      'ne': 'KDOX',
      'w': 'KFCX',
      'sw': 'KRAX',
      's': 'KMHX'
    }
  },
  'KAMA': {
    'rdrName': 'Amarillo, TX',
    'rdrLonLat': [
      -101.70920,
      35.23330
    ],
    'wfoId': 'AMA',
    'wfoName': 'Amarillo, TX',
    'neighbors': {
      'nw': 'KPUX',
      'n': 'KDDC',
      'ne': 'KVNX',
      'w': 'KABX',
      'e': 'KTLX',
      'sw': 'KFDX',
      's': 'KLBB',
      'se': 'KFDR'
    }
  },
  'KAMX': {
    'rdrName': 'Miami, FL',
    'rdrLonLat': [
      -80.41280,
      25.61110
    ],
    'wfoId': 'MFL',
    'wfoName': 'Miami, FL',
    'neighbors': {
      'nw': 'KTBW',
      'n': 'KMLB',
      'sw': 'KBYX',
      'se': 'TJUA'
    }
  },
  'KAPX': {
    'rdrName': 'Gaylord, MI',
    'rdrLonLat': [
      -84.71970,
      44.90720
    ],
    'wfoId': 'APX',
    'wfoName': 'Gaylord/Alpena, MI',
    'neighbors': {
      'nw': 'KMQT',
      'w': 'KGRB',
      'sw': 'KMKX',
      's': 'KGRR',
      'se': 'KDTX'
    }
  },
  'KARX': {
    'rdrName': 'La Crosse, WI',
    'rdrLonLat': [
      -91.19110,
      43.82280
    ],
    'wfoId': 'ARX',
    'wfoName': 'La Crosse, WI',
    'neighbors': {
      'nw': 'KMPX',
      'n': 'KDLH',
      'ne': 'KGRB',
      'w': 'KFSD',
      'e': 'KMKX',
      'sw': 'KDMX',
      's': 'KDVN',
      'se': 'KLOT'
    }
  },
  'KATX': {
    'rdrName': 'Seattle, WA',
    'rdrLonLat': [
      -122.49580,
      48.19440
    ],
    'wfoId': 'SEW',
    'wfoName': 'Seattle/Tacoma, WA',
    'neighbors': {
      'e': 'KOTX',
      'sw': 'KLGX',
      's': 'KRTX',
      'se': 'KPDT'
    }
  },
  'KBBX': {
    'rdrName': 'Sacramento, CA',
    'rdrLonLat': [
      -121.631649,
      39.49611
    ],
    'wfoId': 'STO',
    'wfoName': 'Sacramento, CA',
    'neighbors': {
      'nw': 'KBHX',
      'n': 'KMAX',
      'ne': 'KCBX',
      'e': 'KRGX',
      'sw': 'KMUX',
      's': 'KDAX',
      'se': 'KESX'
    }
  },
  'KBGM': {
    'rdrName': 'Binghamton, NY',
    'rdrLonLat': [
      -75.98470,
      42.19970
    ],
    'wfoId': 'BGM',
    'wfoName': 'Binghamton, NY',
    'neighbors': {
      'nw': 'KBUF',
      'n': 'KTYX',
      'ne': 'KCXX',
      'w': 'KCLE',
      'e': 'KENX',
      'sw': 'KPBZ',
      's': 'KCCX',
      'se': 'KDIX'
    }
  },
  'KBHX': {
    'rdrName': 'Eureka, CA',
    'rdrLonLat': [
      -124.29190,
      40.49830
    ],
    'wfoId': 'EKA',
    'wfoName': 'Eureka, CA',
    'neighbors': {
      'ne': 'KMAX',
      'e': 'KBBX',
      's': 'KMUX',
      'se': 'KDAX'
    }
  },
  'KBIS': {
    'rdrName': 'Bismark, ND',
    'rdrLonLat': [
      -100.760,
      46.7708
    ],
    'wfoId': 'BIS',
    'wfoName': 'Bismarck, ND',
    'neighbors': {
      'nw': 'KGGW',
      'n': 'KMBX',
      'ne': 'KMVX',
      'w': 'KBLX',
      'e': 'KDLH',
      'sw': 'KUDX',
      's': 'KLNX',
      'se': 'KABR'
    }
  },
  'KBLX': {
    'rdrName': 'Billings, MT',
    'rdrLonLat': [
      -108.60670,
      45.85390
    ],
    'wfoId': 'BYZ',
    'wfoName': 'Billings, MT',
    'neighbors': {
      'nw': 'KTFX',
      'n': 'KGGW',
      'ne': 'KMBX',
      'w': 'KMSX',
      'e': 'KUDX',
      'sw': 'KSFX',
      's': 'KRIW',
      'se': 'KCYS'
    }
  },
  'KBMX': {
    'rdrName': 'Alabaster, AL',
    'rdrLonLat': [
      -86.76970,
      33.17220
    ],
    'wfoId': 'BMX',
    'wfoName': 'Birmingham, AL',
    'neighbors': {
      'nw': 'KNQA',
      'n': 'KHTX',
      'ne': 'KFFC',
      'w': 'KGWX',
      'e': 'KJGX',
      'sw': 'KMOB',
      's': 'KEOX',
      'se': 'KMXX'
    }
  },
  'KBOX': {
    'rdrName': 'Taunton, MA',
    'rdrLonLat': [
      -71.13690,
      41.95580
    ],
    'wfoId': 'BOX',
    'wfoName': 'Boston/Norton, MA',
    'neighbors': {
      'nw': 'KCXX',
      'n': 'KGYX',
      'ne': 'KCBW',
      'w': 'KENX',
      'sw': 'KOKX'
    }
  },
  'KBRO': {
    'rdrName': 'Brownsville, TX',
    'rdrLonLat': [
      -97.41890,
      25.91610
    ],
    'wfoId': 'BRO',
    'wfoName': 'Brownsville, TX',
    'neighbors': {
      'nw': 'KDFX',
      'n': 'KCRP',
      'ne': 'KHGX'
    }
  },
  'KBUF': {
    'rdrName': 'Buffalo, NY',
    'rdrLonLat': [
      -78.73670,
      42.94890
    ],
    'wfoId': 'BUF',
    'wfoName': 'Buffalo, NY',
    'neighbors': {
      'ne': 'KTYX',
      'w': 'KDTX',
      'e': 'KENX',
      'sw': 'KCLE',
      's': 'KCCX',
      'se': 'KBGM'
    }
  },
  'KBYX': {
    'rdrName': 'Boca Chica Key, FL',
    'rdrLonLat': [
      -81.7033,
      24.5969
    ],
    'wfoId': 'KEY',
    'wfoName': 'Key West, FL',
    'neighbors': {
      'n': 'KTBW',
      'ne': 'KMLB',
      'e': 'KAMX'
    }
  },
  'KCAE': {
    'rdrName': 'West Columbia, SC',
    'rdrLonLat': [
      -81.11830,
      33.94860
    ],
    'wfoId': 'CAE',
    'wfoName': 'Columbia, SC',
    'neighbors': {
      'nw': 'KGSP',
      'ne': 'KRAX',
      'w': 'KFFC',
      'e': 'KLTX',
      'sw': 'KJGX',
      's': 'KCLX'
    }
  },
  'KCBW': {
    'rdrName': 'Houlton, ME',
    'rdrLonLat': [
      -67.80640,
      46.03920
    ],
    'wfoId': 'CAR',
    'wfoName': 'Caribou, ME',
    'neighbors': {
      'w': 'KCXX',
      'sw': 'KGYX',
      's': 'KBOX'
    }
  },
  'KCBX': {
    'rdrName': 'Boise, ID',
    'rdrLonLat': [
      -116.23560,
      43.49060
    ],
    'wfoId': 'BOI',
    'wfoName': 'Boise, ID',
    'neighbors': {
      'nw': 'KPDT',
      'n': 'KMSX',
      'ne': 'KTFX',
      'w': 'KMAX',
      'e': 'KSFX',
      'sw': 'KRGX',
      's': 'KLRX',
      'se': 'KMTX'
    }
  },
  'KCCX': {
    'rdrName': 'State College, PA',
    'rdrLonLat': [
      -78.00360,
      40.92310
    ],
    'wfoId': 'CTP',
    'wfoName': 'State College/Central, PA',
    'neighbors': {
      'nw': 'KCLE',
      'n': 'KBUF',
      'ne': 'KBGM',
      'w': 'KPBZ',
      'e': 'KOKX',
      'sw': 'KRLX',
      's': 'KLWX',
      'se': 'KDIX'
    }
  },
  'KCLE': {
    'rdrName': 'Cleveland, OH',
    'rdrLonLat': [
      -81.85970,
      41.41310
    ],
    'wfoId': 'CLE',
    'wfoName': 'Cleveland, OH',
    'neighbors': {
      'nw': 'KDTX',
      'ne': 'KBUF',
      'w': 'KIWX',
      'e': 'KCCX',
      'sw': 'KILN',
      's': 'KRLX',
      'se': 'KPBZ'
    }
  },
  'KCLX': {
    'rdrName': 'Charleston, SC',
    'rdrLonLat': [
      -81.04220,
      32.65560
    ],
    'wfoId': 'CHS',
    'wfoName': 'Charleston, SC',
    'neighbors': {
      'nw': 'KFFC',
      'n': 'KCAE',
      'ne': 'KLTX',
      'w': 'KJGX',
      'sw': 'KVAX',
      's': 'KJAX'
    }
  },
  'KCRP': {
    'rdrName': 'Corpus Christi, TX',
    'rdrLonLat': [
      -97.51110,
      27.78420
    ],
    'wfoId': 'CRP',
    'wfoName': 'Corpus Christi, TX',
    'neighbors': {
      'nw': 'KDFX',
      'n': 'KEWX',
      'ne': 'KHGX',
      's': 'KBRO'
    }
  },
  'KCXX': {
    'rdrName': 'Colchester, VT',
    'rdrLonLat': [
      -73.166,
      44.51
    ],
    'wfoId': 'BTV',
    'wfoName': 'Burlington, VT',
    'neighbors': {
      'ne': 'KCBW',
      'w': 'KTYX',
      'e': 'KGYX',
      'sw': 'KBGM',
      's': 'KENX',
      'se': 'KBOX'
    }
  },
  'KCYS': {
    'rdrName': 'Cheyenne, WY',
    'rdrLonLat': [
      -104.80610,
      41.15190
    ],
    'wfoId': 'CYS',
    'wfoName': 'Cheyenne, WY',
    'neighbors': {
      'nw': 'KBLX',
      'n': 'KUDX',
      'ne': 'KLNX',
      'w': 'KRIW',
      'e': 'KUEX',
      'sw': 'KGJX',
      's': 'KFTG',
      'se': 'KGLD'
    }
  },
  'KDAX': {
    'rdrName': 'Davis, CA',
    'rdrLonLat': [
      -121.67780,
      38.50110
    ],
    'wfoId': 'STO',
    'wfoName': 'Sacramento, CA',
    'neighbors': {
      'n': 'KBBX',
      'ne': 'KRGX',
      'nw': 'KBHX',
      'e': 'KRGX',
      's': 'KMUX',
      'se': 'KHNX'
    }
  },
  'KDDC': {
    'rdrName': 'Dodge City, KS',
    'rdrLonLat': [
      -99.96890,
      37.76080
    ],
    'wfoId': 'DDC',
    'wfoName': 'Dodge City, KS',
    'neighbors': {
      'nw': 'KGLD',
      'n': 'KUEX',
      'ne': 'KTWX',
      'w': 'KPUX',
      'e': 'KICT',
      'sw': 'KFDX',
      's': 'KAMA',
      'se': 'KVNX'
    }
  },
  'KDGX': {
    'rdrName': 'Brandon/Jackson, MS',
    'rdrLonLat': [
      -89.98430,
      32.28000
    ],
    'wfoId': 'JAN',
    'wfoName': 'Jackson, MS',
    'neighbors': {
      'nw': 'KLZK',
      'n': 'KNQA',
      'ne': 'KGWX',
      'w': 'KSHV',
      'e': 'KMXX',
      'sw': 'KPOE',
      's': 'KHDC',
      'se': 'KMOB'
    }
  },
  'KDFX': {
    'rdrName': 'Laughlin AFB, TX',
    'rdrLonLat': [
      -100.28027,
      29.272500
    ],
    'wfoId': 'EWX',
    'wfoName': 'San Antonio, TX',
    'neighbors': {
      'nw': 'KMAF',
      'n': 'KSJT',
      'ne': 'KGRK',
      'e': 'KEWX',
      's': 'KBRO',
      'se': 'KCRP'
    }
  },
  'KDIX': {
    'rdrName': 'Fort Dix, NJ',
    'rdrLonLat': [
      -74.41080,
      39.94690
    ],
    'wfoId': 'PHI',
    'wfoName': 'Mt. Holly/Philadelphia, NJ',
    'neighbors': {
      'nw': 'KCCX',
      'n': 'KBGM',
      'ne': 'KOKX',
      'w': 'KLWX',
      'sw': 'KDOX'
    }
  },
  'KDLH': {
    'rdrName': 'Duluth, MN',
    'rdrLonLat': [
      -92.20970,
      46.83690
    ],
    'wfoId': 'DLH',
    'wfoName': 'Duluth, MN',
    'neighbors': {
      'w': 'KMVX',
      'e': 'KMQT',
      'sw': 'KMPX',
      's': 'KARX',
      'se': 'KGRB'
    }
  },
  'KDMX': {
    'rdrName': 'Des Moines, IA',
    'rdrLonLat': [
      -93.72280,
      41.73110
    ],
    'wfoId': 'DMX',
    'wfoName': 'Des Moines, IA',
    'neighbors': {
      'nw': 'KFSD',
      'n': 'KMPX',
      'ne': 'KARX',
      'w': 'KOAX',
      'e': 'KDVN',
      'sw': 'KTWX',
      's': 'KEAX',
      'se': 'KLSX'
    }
  },
  'KDOX': {
    'rdrName': 'Dover AFB, DE',
    'rdrLonLat': [
      -75.43970,
      38.82560
    ],
    'wfoId': 'AKQ',
    'wfoName': 'Wakefield, VA',
    'neighbors': {
      'nw': 'KCCX',
      'n': 'KBGM',
      'ne': 'KDIX',
      'w': 'KLWX',
      'sw': 'KFCX',
      's': 'KAKQ'
    }
  },
  'KDTX': {
    'rdrName': 'White Lake, MI',
    'rdrLonLat': [
      -83.47170,
      42.69970
    ],
    'wfoId': 'DTX',
    'wfoName': 'Detroit, MI',
    'neighbors': {
      'nw': 'KMQT',
      'n': 'KAPX',
      'w': 'KGRR',
      'e': 'KBUF',
      'sw': 'KIWX',
      's': 'KILN',
      'se': 'KCLE'
    }
  },
  'KDYX': {
    'rdrName': 'Dyess AFB, TX',
    'rdrLonLat': [
      -99.25415,
      32.53833
    ],
    'wfoId': 'SJT',
    'wfoName': 'San Angelo, TX',
    'neighbors': {
      'nw': 'KAMA',
      'n': 'KFDR',
      'ne': 'KTLX',
      'w': 'KLBB',
      'e': 'KFWS',
      'sw': 'KMAF',
      's': 'KSJT',
      'se': 'KGRK'
    }
  },
  'KDVN': {
    'rdrName': 'Davenport, IA',
    'rdrLonLat': [
      -90.58080,
      41.61170
    ],
    'wfoId': 'DVN',
    'wfoName': 'Quad Cities, IA',
    'neighbors': {
      'nw': 'KMPX',
      'n': 'KARX',
      'ne': 'KMKX',
      'w': 'KDMX',
      'e': 'KLOT',
      'sw': 'KEAX',
      's': 'KLSX',
      'se': 'KILX'
    }
  },
  'KEAX': {
    'rdrName': 'Pleasant Hill, MO',
    'rdrLonLat': [
      -94.26390,
      38.80970
    ],
    'wfoId': 'EAX',
    'wfoName': 'Kansas City/Pleasant Hill, MO',
    'neighbors': {
      'nw': 'KOAX',
      'n': 'KDMX',
      'ne': 'KDVN',
      'w': 'KTWX',
      'e': 'KLSX',
      'sw': 'KICT',
      's': 'KSGF',
      'se': 'KVWX'
    }
  },
  'KEMX': {
    'rdrName': 'Tucson, AZ',
    'rdrLonLat': [
      -110.63030,
      31.89360
    ],
    'wfoId': 'TWC',
    'wfoName': 'Tucson, AZ',
    'neighbors': {
      'n': 'KFSX',
      'nw': 'KIWA',
      'ne': 'KABX',
      'w': 'KYUX',
      'e': 'KHDX',
      'se': 'KEPZ'
    }
  },
  'KENX': {
    'rdrName': 'East Berne, NY',
    'rdrLonLat': [
      -74.06390,
      42.58640
    ],
    'wfoId': 'ALY',
    'wfoName': 'Albany, NY',
    'neighbors': {
      'nw': 'KTYX',
      'n': 'KCXX',
      'ne': 'KGYX',
      'w': 'KBUF',
      'e': 'KBOX',
      'sw': 'KBGM',
      's': 'KDIX',
      'se': 'KOKX'
    }
  },
  'KEPZ': {
    'rdrName': 'Santa Teresa, NM',
    'rdrLonLat': [
      -106.69799,
      31.8730
    ],
    'wfoId': 'EPZ',
    'wfoName': 'El Paso, TX',
    'neighbors': {
      'nw': 'KFSX',
      'n': 'KHDX',
      'ne': 'KLBB',
      'w': 'KEMX',
      'e': 'KMAF',
      'se': 'KDFX'
    }
  },
  'KEOX': {
    'rdrName': 'Fort Rucker, AL',
    'rdrLonLat': [
      -85.4593,
      31.4605
    ],
    'wfoId': 'TAE',
    'wfoName': 'Tallahassee, FL',
    'neighbors': {
      'nw': 'KBMX',
      'n': 'KMXX',
      'ne': 'KJGX',
      'w': 'KDGX',
      'e': 'KVAX',
      'sw': 'KMOB',
      's': 'KEVX',
      'se': 'KTLH'
    }
  },
  'KESX': {
    'rdrName': 'Las Vegas, NV',
    'rdrLonLat': [
      -114.89138,
      35.70111
    ],
    'wfoId': 'VEF',
    'wfoName': 'Las Vegas, NV',
    'neighbors': {
      'nw': 'KRGX',
      'n': 'KLRX',
      'ne': 'KICX',
      'w': 'KEYX',
      'e': 'KFSX',
      'sw': 'KSOX',
      's': 'KYUX',
      'se': 'KIWA'
    }
  },
  'KEWX': {
    'rdrName': 'New Braunfels, TX',
    'rdrLonLat': [
      -98.02830,
      29.70390
    ],
    'wfoId': 'EWX',
    'wfoName': 'Austin/San Antonio, TX',
    'neighbors': {
      'nw': 'KSJT',
      'n': 'KFWS',
      'ne': 'KGRK',
      'w': 'KDFX',
      'e': 'KHGX',
      's': 'KBRO',
      'se': 'KCRP'
    }
  },
  'KEVX': {
    'rdrName': 'Eglin Afb, FL',
    'rdrLonLat': [
      -85.92166,
      30.56503
    ],
    'wfoId': 'VEF',
    'wfoName': 'Mobile, AL',
    'neighbors': {
      'nw': 'KDGX',
      'n': 'KMXX',
      'ne': 'KEOX',
      'w': 'KMOB',
      'e': 'KTLH',
      'se': 'KTBW'
    }
  },
  'KEYX': {
    'rdrName': 'Edwards AFB, CA',
    'rdrLonLat': [
      -117.56080,
      35.09780
    ],
    'wfoId': 'VEF',
    'wfoName': 'Las Vegas, NV',
    'neighbors': {
      'nw': 'KHNX',
      'n': 'KRGX',
      'ne': 'KLRX',
      'w': 'KVTX',
      'e': 'KESX',
      'sw': 'KSOX',
      's': 'KNKX',
      'se': 'KYUX'
    }
  },
  'KFCX': {
    'rdrName': 'Roanoke, VA',
    'rdrLonLat': [
      -80.27390,
      37.02440
    ],
    'wfoId': 'RNK',
    'wfoName': 'Blacksburg/Roanoke, VA',
    'neighbors': {
      'nw': 'KRLX',
      'n': 'KLWX',
      'ne': 'KDOX',
      'w': 'KJKL',
      'e': 'KAKQ',
      'sw': 'KMRX',
      's': 'KGSP',
      'se': 'KRAX'
    }
  },
  'KFDR': {
    'rdrName': 'Norman, OK',
    'rdrLonLat': [
      -98.97665,
      34.36218
    ],
    'wfoId': 'OUN',
    'wfoName': 'Norman, OK',
    'neighbors': {
      'nw': 'KPUX',
      'n': 'KDDC',
      'ne': 'KVNX',
      'w': 'KAMA',
      'e': 'KTLX',
      'sw': 'KLBB',
      's': 'KDYX',
      'se': 'KFWS'
    }
  },
  'KFDX': {
    'rdrName': 'Albuquerque, NM',
    'rdrLonLat': [
      -103.63000,
      34.63530
    ],
    'wfoId': 'ABQ',
    'wfoName': 'Albuquerque, NM',
    'neighbors': {
      'nw': 'KGJX',
      'n': 'KPUX',
      'ne': 'KAMA',
      'w': 'KABX',
      'e': 'KLBB',
      'sw': 'KEMX',
      's': 'KHDX',
      'se': 'KMAF'
    }
  },
  'KFFC': {
    'rdrName': 'Peachtree City, GA',
    'rdrLonLat': [
      -84.5658,
      33.3633
    ],
    'wfoId': 'FFC',
    'wfoName': 'Peachtree City/Atlanta, GA',
    'neighbors': {
      'nw': 'KHTX',
      'n': 'KMRX',
      'ne': 'KGSP',
      'w': 'KBMX',
      'e': 'KCAE',
      'sw': 'KMXX',
      's': 'KTLH',
      'se': 'KJGX'
    }
  },
  'KFSD': {
    'rdrName': 'Sioux Falls, SD',
    'rdrLonLat': [
      -96.72940,
      43.58780
    ],
    'wfoId': 'FSD',
    'wfoName': 'Sioux Falls, SD',
    'neighbors': {
      'nw': 'KABR',
      'n': 'KMVX',
      'ne': 'KMPX',
      'w': 'KUDX',
      'e': 'KARX',
      'sw': 'KLNX',
      's': 'KOAX',
      'se': 'KDMX'
    }
  },
  'KFSX': {
    'rdrName': 'Flagstaff, AZ',
    'rdrLonLat': [
      -111.19780,
      34.57440
    ],
    'wfoId': 'FGZ',
    'wfoName': 'Flagstaff, AZ',
    'neighbors': {
      'nw': 'KLRX',
      'n': 'KMTX',
      'ne': 'KGJX',
      'w': 'KESX',
      'e': 'KFDX',
      'sw': 'KSOX',
      's': 'KIWA',
      'se': 'KEMX'
    }
  },
  'KFTG': {
    'rdrName': 'Denver, CO',
    'rdrLonLat': [
      -104.54580,
      39.78670
    ],
    'wfoId': 'BOU',
    'wfoName': 'Denver/Boulder, CO',
    'neighbors': {
      'nw': 'KRIW',
      'n': 'KCYS',
      'ne': 'KLNX',
      'w': 'KGJX',
      'e': 'KGLD',
      's': 'KPUX',
      'se': 'KDDC'
    }
  },
  'KFWS': {
    'rdrName': 'Fort Worth, TX',
    'rdrLonLat': [
      -97.30312,
      32.5727
    ],
    'wfoId': 'FWD',
    'wfoName': 'Dallas/Fort Worth, TX',
    'neighbors': {
      'nw': 'KFDR',
      'n': 'KTLX',
      'ne': 'KSRX',
      'w': 'KDYX',
      'e': 'KSHV',
      'sw': 'KSJT',
      's': 'KGRK',
      'se': 'KHGX'
    }
  },
  'KGGW': {
    'rdrName': 'Glasgow, MT',
    'rdrLonLat': [
      -106.62500,
      48.20640
    ],
    'wfoId': 'GGW',
    'wfoName': 'Glasgow, MT',
    'neighbors': {
      'w': 'KTFX',
      'e': 'KMBX',
      's': 'KBLX',
      'se': 'KUDX'
    }
  },
  'KGJX': {
    'rdrName': 'Grand Junction, CO',
    'rdrLonLat': [
      -108.21390,
      39.06220
    ],
    'wfoId': 'GJT',
    'wfoName': 'Grand Junction, CO',
    'neighbors': {
      'nw': 'KSFX',
      'n': 'KRIW',
      'ne': 'KCYS',
      'w': 'KMTX',
      'e': 'KFTG',
      'sw': 'KICX',
      's': 'KABX',
      'se': 'KPUX'
    }
  },
  'KGLD': {
    'rdrName': 'Goodland, KS',
    'rdrLonLat': [
      -101.69970,
      39.36580
    ],
    'wfoId': 'GLD',
    'wfoName': 'Goodland, KS',
    'neighbors': {
      'nw': 'KCYS',
      'n': 'KLNX',
      'ne': 'KUEX',
      'w': 'KFTG',
      'e': 'KTWX',
      'sw': 'KPUX',
      's': 'KDDC',
      'se': 'KICT'
    }
  },
  'KGRB': {
    'rdrName': 'Green Bay, WI',
    'rdrLonLat': [
      -88.11140,
      44.49830
    ],
    'wfoId': 'GRB',
    'wfoName': 'Green Bay, WI',
    'neighbors': {
      'nw': 'KDLH',
      'n': 'KMQT',
      'ne': 'KAPX',
      'w': 'KMPX',
      'e': 'KAPX',
      'sw': 'KARX',
      's': 'KMKX',
      'se': 'KGRR'
    }
  },
  'KGRK': {
    'rdrName': 'Ft Hood, TX',
    'rdrLonLat': [
      -97.38310,
      30.72190
    ],
    'wfoId': 'FWD',
    'wfoName': 'Dallas/Fort Worth, TX',
    'neighbors': {
      'nw': 'KDYX',
      'n': 'KFWS',
      'ne': 'KSHV',
      'w': 'KSJT',
      'e': 'KPOE',
      'sw': 'KDFX',
      's': 'KEWX',
      'se': 'KHGX'
    }
  },
  'KGRR': {
    'rdrName': 'Grand Rapids, MI',
    'rdrLonLat': [
      -85.54470,
      42.89390
    ],
    'wfoId': 'GRR',
    'wfoName': 'Grand Rapids, MI',
    'neighbors': {
      'nw': 'KGRB',
      'n': 'KAPX',
      'w': 'KMKX',
      'e': 'KDTX',
      'sw': 'KLOT',
      's': 'KIWX',
      'se': 'KCLE'
    }
  },
  'KGSP': {
    'rdrName': 'Greer, SC',
    'rdrLonLat': [
      -82.22000,
      34.88330
    ],
    'wfoId': 'GSP',
    'wfoName': 'Greenville/Spartanburg, SC',
    'neighbors': {
      'nw': 'KMRX',
      'ne': 'KFCX',
      'e': 'KRAX',
      'sw': 'KJGX',
      's': 'KCAE',
      'se': 'KLTX'
    }
  },
  'KGWX': {
    'rdrName': 'Jackson, MS',
    'rdrLonLat': [
      -88.329189,
      33.896909
    ],
    'wfoId': 'JAN',
    'wfoName': 'Jackson, MS',
    'neighbors': {
      'nw': 'KNQA',
      'n': 'KOHX',
      'ne': 'KHTX',
      'w': 'KLZK',
      'e': 'KBMX',
      'sw': 'KDGX',
      's': 'KMOB',
      'se': 'KMXX'
    }
  },
  'KGYX': {
    'rdrName': 'Gray, ME',
    'rdrLonLat': [
      -70.25640,
      43.89140
    ],
    'wfoId': 'GYX',
    'wfoName': 'Portland, ME',
    'neighbors': {
      'nw': 'KCXX',
      'n': 'KCBW',
      'w': 'KENX',
      'sw': 'KOKX',
      's': 'KBOX'
    }
  },
  'KHDX': {
    'rdrName': 'Holloman AFB, NM',
    'rdrLonLat': [
      -106.12280,
      33.07640
    ],
    'wfoId': 'EPZ',
    'wfoName': 'El Paso, TX',
    'neighbors': {
      'nw': 'KFSX',
      'n': 'KABX',
      'ne': 'KFDX',
      'w': 'KIWA',
      'e': 'KLBB',
      'sw': 'KEMX',
      's': 'KMAF'
    }
  },
  'KHGX': {
    'rdrName': 'Houston/Galveston, TX',
    'rdrLonLat': [
      -95.07920,
      29.47190
    ],
    'wfoId': 'HGX',
    'wfoName': 'Houston/Galveston, TX',
    'neighbors': {
      'nw': 'KGRK',
      'n': 'KFWS',
      'ne': 'KSHV',
      'w': 'KEWX',
      'e': 'KLCH',
      'sw': 'KCRP',
      's': 'KBRO'
    }
  },
  'KHNX': {
    'rdrName': 'Hanford, CA',
    'rdrLonLat': [
      -119.63220,
      36.31420
    ],
    'wfoId': 'HNX',
    'wfoName': 'San Joaquin Valley, CA',
    'neighbors': {
      'nw': 'KDAX',
      'n': 'KRGX',
      'ne': 'KLRX',
      'w': 'KMUX',
      'e': 'KESX',
      's': 'KVBX',
      'se': 'KEYX'
    }
  },
  'KHPX': {
    'rdrName': 'Ft Campbell, KY',
    'rdrLonLat': [
      -87.28498,
      36.7366
    ],
    'wfoId': 'PAH',
    'wfoName': 'Paducah, KY',
    'neighbors': {
      'nw': 'KLSX',
      'n': 'KVWX',
      'ne': 'KLVX',
      'w': 'KPAH',
      'e': 'KJKL',
      'sw': 'KNQA',
      's': 'KOHX',
      'se': 'KOHX'
    }
  },
  'KHTX': {
    'rdrName': 'Hytop, AL',
    'rdrLonLat': [
      -86.08330,
      34.93060
    ],
    'wfoId': 'HUN',
    'wfoName': 'Huntsville, AL',
    'neighbors': {
      'nw': 'KPAH',
      'n': 'KOHX',
      'ne': 'KMRX',
      'w': 'KNQA',
      'e': 'KGSP',
      'sw': 'KGWX',
      's': 'KBMX',
      'se': 'KFFC'
    }
  },
  'KICT': {
    'rdrName': 'Wichita, KS',
    'rdrLonLat': [
      -97.44280,
      37.65390
    ],
    'wfoId': 'ICT',
    'wfoName': 'Wichita, KS',
    'neighbors': {
      'nw': 'KGLD',
      'n': 'KUEX',
      'ne': 'KTWX',
      'w': 'KDDC',
      'e': 'KSGF',
      'sw': 'KAMA',
      's': 'KVNX',
      'se': 'KINX'
    }
  },
  'KILN': {
    'rdrName': 'Wilmington, OH',
    'rdrLonLat': [
      -83.82170,
      39.42030
    ],
    'wfoId': 'ILN',
    'wfoName': 'Wilmington/Cincinnati, OH',
    'neighbors': {
      'nw': 'KIWX',
      'n': 'KDTX',
      'ne': 'KCLE',
      'w': 'KIND',
      'e': 'KPBZ',
      'sw': 'KLVX',
      's': 'KJKL',
      'se': 'KRLX'
    }
  },
  'KICX': {
    'rdrName': 'Cedar City, UT',
    'rdrLonLat': [
      -112.86221,
      37.59104
    ],
    'wfoId': 'SLC',
    'wfoName': 'Salt Lake Cuty, UT',
    'neighbors': {
      'nw': 'KLRX',
      'n': 'KMTX',
      'ne': 'KRIW',
      'w': 'KHNX',
      'e': 'KGJX',
      'sw': 'KESX',
      's': 'KFSX',
      'se': 'KABX'
    }
  },
  'KILX': {
    'rdrName': 'Lincoln, IL',
    'rdrLonLat': [
      -89.33690,
      40.15060
    ],
    'wfoId': 'ILX',
    'wfoName': 'Lincoln/Central Illinois, IL',
    'neighbors': {
      'nw': 'KDVN',
      'n': 'KLOT',
      'ne': 'KIWX',
      'w': 'KEAX',
      'e': 'KIND',
      'sw': 'KLSX',
      's': 'KVWX',
      'se': 'KLVX'
    }
  },
  'KIND': {
    'rdrName': 'Indianapolis, IN',
    'rdrLonLat': [
      -86.28030,
      39.70750
    ],
    'wfoId': 'IND',
    'wfoName': 'Indianapolis, IN',
    'neighbors': {
      'nw': 'KLOT',
      'n': 'KIWX',
      'ne': 'KDTX',
      'w': 'KILX',
      'e': 'KILN',
      'sw': 'KVWX',
      's': 'KLVX',
      'se': 'KJKL'
    }
  },
  'KINX': {
    'rdrName': 'Inola, OK',
    'rdrLonLat': [
      -95.56410,
      36.17499
    ],
    'wfoId': 'TSA',
    'wfoName': 'Tulsa, OK',
    'neighbors': {
      'nw': 'KICT',
      'n': 'KTWX',
      'ne': 'KSGF',
      'w': 'KVNX',
      'e': 'KSRX',
      'sw': 'KTLX',
      's': 'KFWS',
      'se': 'KSHV'
    }
  },
  'KIWA': {
    'rdrName': 'Phoenix, AZ',
    'rdrLonLat': [
      -111.66999,
      33.28916
    ],
    'wfoId': 'PSR',
    'wfoName': 'Phoenix, AZ',
    'neighbors': {
      'nw': 'KESX',
      'n': 'KICX',
      'ne': 'KFSX',
      'w': 'KYUX',
      'e': 'KHDX',
      's': 'KEMX',
      'se': 'KEPZ'
    }
  },
  'KIWX': {
    'rdrName': 'North Webster, IN',
    'rdrLonLat': [
      -85.70000,
      41.35890
    ],
    'wfoId': 'IWX',
    'wfoName': 'Northern Indiana, IN',
    'neighbors': {
      'nw': 'KMKX',
      'n': 'KGRR',
      'ne': 'KDTX',
      'w': 'KLOT',
      'e': 'KCLE',
      'sw': 'KILX',
      's': 'KIND',
      'se': 'KILN'
    }
  },
  'KJAX': {
    'rdrName': 'Jacksonville, FL',
    'rdrLonLat': [
      -81.70190,
      30.48470
    ],
    'wfoId': 'JAX',
    'wfoName': 'Jacksonville, FL',
    'neighbors': {
      'nw': 'KJGX',
      'n': 'KCLX',
      'w': 'KTLH',
      'e': 'TJUA',
      'sw': 'KTBW',
      's': 'KMLB'
    }
  },
  'KJGX': {
    'rdrName': 'Robins AFB, GA',
    'rdrLonLat': [
      -83.35110,
      32.67530
    ],
    'wfoId': 'FFC',
    'wfoName': 'Atlanta, GA',
    'neighbors': {
      'nw': 'KFFC',
      'n': 'KGSP',
      'ne': 'KCAE',
      'w': 'KMXX',
      'e': 'KCLX',
      'sw': 'KEOX',
      's': 'KVAX',
      'se': 'KJAX'
    }
  },
  'KJKL': {
    'rdrName': 'Jackson, KY',
    'rdrLonLat': [
      -83.31310,
      37.59080
    ],
    'wfoId': 'JKL',
    'wfoName': 'Jackson, KY',
    'neighbors': {
      'nw': 'KIND',
      'n': 'KILN',
      'ne': 'KRLX',
      'w': 'KLVX',
      'e': 'KFCX',
      'sw': 'KOHX',
      's': 'KMRX',
      'se': 'KRAX'
    }
  },
  'KLBB': {
    'rdrName': 'Lubbock, TX',
    'rdrLonLat': [
      -101.81420,
      33.65420
    ],
    'wfoId': 'LUB',
    'wfoName': 'Lubbock, TX',
    'neighbors': {
      'nw': 'KPUX',
      'n': 'KAMA',
      'ne': 'KVNX',
      'w': 'KFDX',
      'e': 'KFWS',
      'sw': 'KHDX',
      's': 'KMAF',
      'se': 'KSJT'
    }
  },
  'KLCH': {
    'rdrName': 'Lake Charles, LA',
    'rdrLonLat': [
      -93.2158,
      30.1252
    ],
    'wfoId': 'LCH',
    'wfoName': 'Lake Charles, LA',
    'neighbors': {
      'nw': 'KFWS',
      'n': 'KSHV',
      'ne': 'KPOE',
      'w': 'KGRK',
      'e': 'KHDC',
      'sw': 'KHGX'
    }
  },
  'KLGX': {
    'rdrName': 'Langley Hill, WA',
    'rdrLonLat': [
      -124.10663,
      47.116
    ],
    'wfoId': 'SEW',
    'wfoName': 'Seattle/Tacoma, WA',
    'neighbors': {
      'ne': 'KATX',
      's': 'KRTX',
      'e': 'KOTX',
      'se': 'KPDT'
    }
  },
  'KHDC': {
    'rdrName': 'Hammond, LA',
    'rdrLonLat': [
      -90.407,
      30.519
    ],
    'wfoId': 'LIX',
    'wfoName': 'New Orleans/Baton Rouge, LA',
    'neighbors': {
      'nw': 'KSHV',
      'n': 'KDGX',
      'ne': 'KMXX',
      'w': 'KLCH',
      'e': 'KMOB'
    }
  },
  'KLNX': {
    'rdrName': 'North Platte, NE',
    'rdrLonLat': [
      -100.57640,
      41.95780
    ],
    'wfoId': 'LBF',
    'wfoName': 'North Platte, NE',
    'neighbors': {
      'nw': 'KUDX',
      'n': 'KABR',
      'ne': 'KFSD',
      'w': 'KCYS',
      'e': 'KOAX',
      'sw': 'KFTG',
      's': 'KGLD',
      'se': 'KUEX'
    }
  },
  'KLRX': {
    'rdrName': 'Elko, NV',
    'rdrLonLat': [
      -116.80280,
      40.73970
    ],
    'wfoId': 'LKN',
    'wfoName': 'Elko, NV',
    'neighbors': {
      'nw': 'KMAX',
      'n': 'KCBX',
      'ne': 'KSFX',
      'w': 'KRGX',
      'e': 'KMTX',
      'sw': 'KHNX',
      's': 'KESX',
      'se': 'KICX'
    }
  },
  'KLOT': {
    'rdrName': 'Romeoville, IL',
    'rdrLonLat': [
      -88.08470,
      41.60470
    ],
    'wfoId': 'LOT',
    'wfoName': 'Chicago, IL',
    'neighbors': {
      'nw': 'KARX',
      'n': 'KMKX',
      'ne': 'KGRR',
      'w': 'KDVN',
      'e': 'KIWX',
      'sw': 'KLSX',
      's': 'KILX',
      'se': 'KIND'
    }
  },
  'KLSX': {
    'rdrName': 'Weldon Springs, MO',
    'rdrLonLat': [
      -90.68280,
      38.69890
    ],
    'wfoId': 'LSX',
    'wfoName': 'St. Louis, MO',
    'neighbors': {
      'nw': 'KDMX',
      'n': 'KDVN',
      'ne': 'KILX',
      'w': 'KEAX',
      'sw': 'KSGF',
      's': 'KPAH',
      'se': 'KVWX'
    }
  },
  'KLTX': {
    'rdrName': 'Shallotte, NC',
    'rdrLonLat': [
      -78.42890,
      33.98940
    ],
    'wfoId': 'ILM',
    'wfoName': 'Wilmington, NC',
    'neighbors': {
      'nw': 'KGSP',
      'n': 'KRAX',
      'ne': 'KMHX',
      'w': 'KCAE',
      'sw': 'KCLX'
    }
  },
  'KLVX': {
    'rdrName': 'Fort Knox, KY',
    'rdrLonLat': [
      -85.94390,
      37.97530
    ],
    'wfoId': 'LMK',
    'wfoName': 'Louisville, KY',
    'neighbors': {
      'nw': 'KILX',
      'n': 'KIND',
      'ne': 'KILN',
      'w': 'KVWX',
      'e': 'KJKL',
      'sw': 'KHPX',
      's': 'KOHX',
      'se': 'KMRX'
    }
  },
  'KLWX': {
    'rdrName': 'Sterling, VA',
    'rdrLonLat': [
      -77.47780,
      38.97530
    ],
    'wfoId': 'LWX',
    'wfoName': 'Baltimore, MD/ Washington DC',
    'neighbors': {
      'nw': 'KPBZ',
      'n': 'KCCX',
      'ne': 'KDIX',
      'w': 'KRLX',
      'e': 'KDOX',
      'sw': 'KRLX',
      's': 'KFCX',
      'se': 'KAKQ'
    }
  },
  'KLZK': {
    'rdrName': 'North Little Rock, AR',
    'rdrLonLat': [
      -92.26220,
      34.83640
    ],
    'wfoId': 'LZK',
    'wfoName': 'Little Rock, AR',
    'neighbors': {
      'nw': 'KSRX',
      'n': 'KSGF',
      'ne': 'KVWX',
      'w': 'KVNX',
      'e': 'KNQA',
      'sw': 'KFWS',
      's': 'KSHV',
      'se': 'KDGX'
    }
  },
  'KMAF': {
    'rdrName': 'Midland, TX',
    'rdrLonLat': [
      -102.18920,
      31.94330
    ],
    'wfoId': 'MAF',
    'wfoName': 'Midland/Odessa, TX',
    'neighbors': {
      'nw': 'KFDX',
      'n': 'KLBB',
      'ne': 'KDYX',
      'w': 'KHDX',
      'e': 'KSJT',
      's': 'KDFX',
      'se': 'KEWX'
    }
  },
  'KMAX': {
    'rdrName': 'Medford, OR',
    'rdrLonLat': [
      -122.71720,
      42.08110
    ],
    'wfoId': 'MFR',
    'wfoName': 'Medford, OR',
    'neighbors': {
      'n': 'KRTX',
      'ne': 'KPDT',
      'e': 'KCBX',
      'sw': 'KBHX',
      's': 'KBBX',
      'se': 'KRGX'
    }
  },
  'KMBX': {
    'rdrName': 'Minot AFB, ND',
    'rdrLonLat': [
      -100.86500,
      48.39250
    ],
    'wfoId': 'BIS',
    'wfoName': 'Bismarck, ND',
    'neighbors': {
      'w': 'KGGW',
      'e': 'KMVX',
      'sw': 'KBLX',
      's': 'KBIS',
      'se': 'KABR'
    }
  },
  'KMLB': {
    'rdrName': 'Melbourne, FL',
    'rdrLonLat': [
      -80.65420,
      28.11330
    ],
    'wfoId': 'MLB',
    'wfoName': 'Melbourne, FL',
    'neighbors': {
      'nw': 'KTLH',
      'n': 'KJAX',
      'ne': 'KCLX',
      'w': 'KTBW',
      'sw': 'KBYX',
      's': 'KAMX'
    }
  },
  'KMHX': {
    'rdrName': 'Newport, NC',
    'rdrLonLat': [
      -76.87610,
      34.77610
    ],
    'wfoId': 'MHX',
    'wfoName': 'Newport/Morehead City, NC',
    'neighbors': {
      'nw': 'KRAX',
      'n': 'KAKQ',
      'w': 'KGSP',
      'sw': 'KCAE',
      's': 'KLTX'
    }
  },
  'KMKX': {
    'rdrName': 'Dousman, WI',
    'rdrLonLat': [
      -88.55060,
      42.96780
    ],
    'wfoId': 'MKX',
    'wfoName': 'Milwaukee, WI',
    'neighbors': {
      'nw': 'KMPX',
      'n': 'KGRB',
      'ne': 'KAPX',
      'w': 'KARX',
      'e': 'KGRR',
      'sw': 'KDVN',
      's': 'KLOT',
      'se': 'KIWX'
    }
  },
  'KMOB': {
    'rdrName': 'Mobile, AL',
    'rdrLonLat': [
      -88.23970,
      30.67940
    ],
    'wfoId': 'MOB',
    'wfoName': 'Mobile, AL',
    'neighbors': {
      'nw': 'KDGX',
      'n': 'KGWX',
      'ne': 'KBMX',
      'w': 'KHDC',
      'e': 'KEOX',
      'se': 'KEVX'
    }
  },
  'KMPX': {
    'rdrName': 'Chanhassen, MN',
    'rdrLonLat': [
      -93.56560,
      44.84890
    ],
    'wfoId': 'MPX',
    'wfoName': 'Minneapolis, MN',
    'neighbors': {
      'nw': 'KMVX',
      'n': 'KDLH',
      'ne': 'KMQT',
      'w': 'KABR',
      'e': 'KGRB',
      'sw': 'KFSD',
      's': 'KDMX',
      'se': 'KARX'
    }
  },
  'KMQT': {
    'rdrName': 'Negaunee, MI',
    'rdrLonLat': [
      -87.54830,
      46.53110
    ],
    'wfoId': 'MQT',
    'wfoName': 'Marquette, MI',
    'neighbors': {
      'w': 'KDLH',
      'e': 'KAPX',
      'sw': 'KMPX',
      's': 'KGRB',
      'se': 'KGRR'
    }
  },
  'KMRX': {
    'rdrName': 'Morristown, TN',
    'rdrLonLat': [
      -83.40170,
      36.16860
    ],
    'wfoId': 'MRX',
    'wfoName': 'Knoxville/Tri-cities, TN',
    'neighbors': {
      'nw': 'KLVX',
      'n': 'KJKL',
      'ne': 'KRLX',
      'w': 'KOHX',
      'e': 'KFCX',
      'sw': 'KHTX',
      's': 'KJGX',
      'se': 'KGSP'
    }
  },
  'KMSX': {
    'rdrName': 'Missoula, MT',
    'rdrLonLat': [
      -113.98610,
      47.04110
    ],
    'wfoId': 'MSO',
    'wfoName': 'Missoula, MT',
    'neighbors': {
      'nw': 'KOTX',
      'w': 'KPDT',
      'e': 'KTFX',
      'sw': 'KCBX',
      's': 'KSFX',
      'se': 'KBLX'
    }
  },
  'KMTX': {
    'rdrName': 'Salt Lake City, UT',
    'rdrLonLat': [
      -112.44777,
      41.26280
    ],
    'wfoId': 'SLC',
    'wfoName': 'Salt Lake City, UT',
    'neighbors': {
      'nw': 'KCBX',
      'n': 'KSFX',
      'ne': 'KRIW',
      'w': 'KLRX',
      'e': 'KGJX',
      'sw': 'KESX',
      's': 'KICX',
      'se': 'KABX'
    }
  },
  'KMUX': {
    'rdrName': 'San Francisco, CA',
    'rdrLonLat': [
      -121.89830,
      37.15530
    ],
    'wfoId': 'MTR',
    'wfoName': 'San Francisco Bay Area, CA',
    'neighbors': {
      'nw': 'KBHX',
      'n': 'KDAX',
      'ne': 'KRGX',
      'se': 'KHNX',
      's': 'KVBX'
    }
  },
  'KMVX': {
    'rdrName': 'Grand Forks, ND',
    'rdrLonLat': [
      -97.32560,
      47.52780
    ],
    'wfoId': 'FGF',
    'wfoName': 'Fargo/Grand Forks, ND',
    'neighbors': {
      'nw': 'KMBX',
      'w': 'KBIS',
      'e': 'KDLH',
      'sw': 'KABR',
      's': 'KFSD',
      'se': 'KMPX'
    }
  },
  'KMXX': {
    'rdrName': 'Maxwell AFB, AL',
    'rdrLonLat': [
      -85.78970,
      32.53670
    ],
    'wfoId': 'BMX',
    'wfoName': 'Birmingham, AL',
    'neighbors': {
      'nw': 'KBMX',
      'n': 'KHTX',
      'ne': 'KFFC',
      'w': 'KDGX',
      'e': 'KJGX',
      'sw': 'KMOB',
      's': 'KEOX',
      'se': 'KVAX'
    }
  },
  'KNKX': {
    'rdrName': 'San Diego, CA',
    'rdrLonLat': [
      -117.0419,
      32.9188
    ],
    'wfoId': 'SGX',
    'wfoName': 'San Diego, CA',
    'neighbors': {
      'nw': 'KVTX',
      'n': 'KEYX',
      'ne': 'KESX',
      'e': 'KFSX',
      'se': 'KYUX'
    }
  },
  'KNQA': {
    'rdrName': 'Millington, TN',
    'rdrLonLat': [
      -89.87330,
      35.34470
    ],
    'wfoId': 'MEG',
    'wfoName': 'Memphis, TN',
    'neighbors': {
      'nw': 'KSGF',
      'n': 'KPAH',
      'ne': 'KOHX',
      'w': 'KLZK',
      'e': 'KHTX',
      'sw': 'KSHV',
      's': 'KDGX',
      'se': 'KGWX'
    }
  },
  'KOAX': {
    'rdrName': 'Valley, NE',
    'rdrLonLat': [
      -96.36670,
      41.32030
    ],
    'wfoId': 'OAX',
    'wfoName': 'Omaha, NE',
    'neighbors': {
      'nw': 'KUDX',
      'n': 'KFSD',
      'ne': 'KMPX',
      'w': 'KLNX',
      'e': 'KDMX',
      'sw': 'KUEX',
      's': 'KTWX',
      'se': 'KEAX'
    }
  },
  'KOHX': {
    'rdrName': 'Old Hickory, TN',
    'rdrLonLat': [
      -86.56250,
      36.24720
    ],
    'wfoId': 'OHX',
    'wfoName': 'Nashville, TN',
    'neighbors': {
      'nw': 'KVWX',
      'n': 'KLVX',
      'ne': 'KJKL',
      'w': 'KPAH',
      'e': 'KMRX',
      'sw': 'KNQA',
      's': 'KHTX',
      'se': 'KGSP'
    }
  },
  'KOKX': {
    'rdrName': 'Upton, NY',
    'rdrLonLat': [
      -72.86390,
      40.86560
    ],
    'wfoId': 'OKX',
    'wfoName': 'New York City, NY',
    'neighbors': {
      'nw': 'KBGM',
      'n': 'KENX',
      'ne': 'KBOX',
      'w': 'KCCX',
      'sw': 'KDIX'
    }
  },
  'KOTX': {
    'rdrName': 'Spokane, WA',
    'rdrLonLat': [
      -117.62670,
      47.68030
    ],
    'wfoId': 'OTX',
    'wfoName': 'Spokane, WA',
    'neighbors': {
      'nw': 'KATX',
      'w': 'KLGX',
      'e': 'KMSX',
      'sw': 'KRTX',
      's': 'KPDT'
    }
  },
  'KPAH': {
    'rdrName': 'PADUCAH, KY',
    'rdrLonLat': [
      -96.36670,
      41.32030
    ],
    'wfoId': 'PAH',
    'wfoName': 'PADUCAH, KY',
    'neighbors': {
      'nw': 'KLSX',
      'n': 'KILX',
      'ne': 'KVWX',
      'w': 'KSGF',
      'e': 'KHPX',
      'sw': 'KLZK',
      's': 'KNQA',
      'se': 'KOHX'
    }
  },
  'KPBZ': {
    'rdrName': 'Pittsburgh, PA',
    'rdrLonLat': [
      -80.21810,
      40.53170
    ],
    'wfoId': 'PBZ',
    'wfoName': 'Pittsburgh, PA',
    'neighbors': {
      'nw': 'KCLE',
      'n': 'KBUF',
      'ne': 'KBGM',
      'w': 'KILN',
      'e': 'KCCX',
      'sw': 'KJKL',
      's': 'KRLX',
      'se': 'KLWX'
    }
  },
  'KPDT': {
    'rdrName': 'Pendleton, OR',
    'rdrLonLat': [
      -118.85280,
      45.69060
    ],
    'wfoId': 'PDT',
    'wfoName': 'Pendleton, OR',
    'neighbors': {
      'nw': 'KATX',
      'n': 'KOTX',
      'ne': 'KMSX',
      'w': 'KRTX',
      'sw': 'KMAX',
      's': 'KRGX',
      'se': 'KCBX'
    }
  },
  'KPOE': {
    'rdrName': 'Fort Polk, LA',
    'rdrLonLat': [
      -92.97580,
      31.15560
    ],
    'wfoId': 'LCH',
    'wfoName': 'Lake Charles, LA',
    'neighbors': {
      'nw': 'KFWS',
      'n': 'KSHV',
      'ne': 'KDGX',
      'w': 'KGRK',
      'e': 'KHDC',
      'sw': 'KHGX',
      's': 'KLCH'
    }
  },
  'KPUX': {
    'rdrName': 'Pueblo, CO',
    'rdrLonLat': [
      -104.18140,
      38.45940
    ],
    'wfoId': 'PUB',
    'wfoName': 'Pueblo, CO',
    'neighbors': {
      'nw': 'KRIW',
      'n': 'KFTG',
      'ne': 'KGLD',
      'w': 'KGJX',
      'e': 'KDDC',
      'sw': 'KABX',
      's': 'KFDX',
      'se': 'KAMA'
    }
  },
  'KRAX': {
    'rdrName': 'Clayton, NC',
    'rdrLonLat': [
      -78.48970,
      35.66560
    ],
    'wfoId': 'RAH',
    'wfoName': 'Raleigh/Durham, NC',
    'neighbors': {
      'n': 'KFCX',
      'ne': 'KAKQ',
      'w': 'KGSP',
      'e': 'KMHX',
      'sw': 'KCAE',
      's': 'KLTX',
      'se': 'KMHX'
    }
  },
  'KRGX': {
    'rdrName': 'Nixon, NV',
    'rdrLonLat': [
      -119.46220,
      39.75420
    ],
    'wfoId': 'REV',
    'wfoName': 'Reno, NV',
    'neighbors': {
      'nw': 'KMAX',
      'n': 'KPDT',
      'ne': 'KCBX',
      'w': 'KBBX',
      'e': 'KLRX',
      'sw': 'KDAX',
      's': 'KHNX',
      'se': 'KESX'
    }
  },
  'KRIW': {
    'rdrName': 'Riverton, WY',
    'rdrLonLat': [
      -108.47720,
      43.06610
    ],
    'wfoId': 'RIW',
    'wfoName': 'Riverton, WY',
    'neighbors': {
      'nw': 'KTFX',
      'n': 'KBLX',
      'ne': 'KUDX',
      'w': 'KSFX',
      'e': 'KCYS',
      'sw': 'KMTX',
      's': 'KGJX',
      'se': 'KFTG'
    }
  },
  'KRLX': {
    'rdrName': 'Charleston, WV',
    'rdrLonLat': [
      -81.72278,
      38.31111
    ],
    'wfoId': 'RLX',
    'wfoName': 'Charleston, WV',
    'neighbors': {
      'nw': 'KILN',
      'n': 'KPBZ',
      'ne': 'KCCX',
      'w': 'KJKL',
      'e': 'KLWX',
      'sw': 'KMRX',
      's': 'KFCX',
      'se': 'KAKQ'
    }
  },
  'KRTX': {
    'rdrName': 'Portland, OR',
    'rdrLonLat': [
      -122.96530,
      45.71470
    ],
    'wfoId': 'PQR',
    'wfoName': 'Portland, OR',
    'neighbors': {
      'nw': 'KLGX',
      'n': 'KATX',
      'ne': 'KOTX',
      'e': 'KPDT',
      's': 'KMAX',
      'se': 'KCBX'
    }
  },
  'KSFX': {
    'rdrName': 'Springfield, ID',
    'rdrLonLat': [
      -112.68610,
      43.10580
    ],
    'wfoId': 'PIH',
    'wfoName': 'Pocatello, ID',
    'neighbors': {
      'nw': 'KMSX',
      'n': 'KTFX',
      'ne': 'KBLX',
      'w': 'KCBX',
      'e': 'KRIW',
      'sw': 'KLRX',
      's': 'KMTX',
      'se': 'KGJX'
    }
  },
  'KSGF': {
    'rdrName': 'Springfield, MO',
    'rdrLonLat': [
      -93.40060,
      37.23530
    ],
    'wfoId': 'SGF',
    'wfoName': 'Springfield, MO',
    'neighbors': {
      'nw': 'KTWX',
      'n': 'KEAX',
      'ne': 'KLSX',
      'w': 'KICT',
      'e': 'KVWX',
      'sw': 'KSRX',
      's': 'KLZK',
      'se': 'KNQA'
    }
  },
  'KSHV': {
    'rdrName': 'Shreveport, LA',
    'rdrLonLat': [
      -93.84140,
      32.45080
    ],
    'wfoId': 'SHV',
    'wfoName': 'Shreveport, LA',
    'neighbors': {
      'nw': 'KSRX',
      'n': 'KLZK',
      'ne': 'KNQA',
      'w': 'KFWS',
      'e': 'KDGX',
      'sw': 'KHGX',
      's': 'KLCH',
      'se': 'KHDC'
    }
  },
  'KSJT': {
    'rdrName': 'San Angelo, TX',
    'rdrLonLat': [
      -100.49250,
      31.37140
    ],
    'wfoId': 'SJT',
    'wfoName': 'San Angelo, TX',
    'neighbors': {
      'nw': 'KLBB',
      'n': 'KDYX',
      'ne': 'KFWS',
      'w': 'KMAF',
      'e': 'KGRK',
      's': 'KDFX',
      'se': 'KEWX'
    }
  },
  'KSOX': {
    'rdrName': 'Santa Ana Mtns, CA',
    'rdrLonLat': [
      -117.63580,
      33.81780
    ],
    'wfoId': 'SGX',
    'wfoName': 'San Diego, CA',
    'neighbors': {
      'nw': 'KVTX',
      'n': 'KEYX',
      'ne': 'KESX',
      'e': 'KFSX',
      's': 'KNKX',
      'se': 'KYUX'
    }
  },
  'KSRX': {
    'rdrName': 'Chaffee Ridge, AR',
    'rdrLonLat': [
      -94.36190,
      35.29080
    ],
    'wfoId': 'TSA',
    'wfoName': 'Tulsa, OK',
    'neighbors': {
      'nw': 'KINX',
      'n': 'KEAX',
      'ne': 'KSGF',
      'w': 'KTLX',
      'e': 'KLZK',
      'sw': 'KFWS',
      's': 'KSHV'
    }
  },
  'KTBW': {
    'rdrName': 'Ruskin, FL',
    'rdrLonLat': [
      -82.40170,
      27.70560
    ],
    'wfoId': 'TBW',
    'wfoName': 'Tampa Bay Area, FL',
    'neighbors': {
      'nw': 'KTLH',
      'n': 'KVAX',
      'ne': 'KJAX',
      'e': 'KMLB',
      's': 'KBYX',
      'se': 'KAMX'
    }
  },
  'KTFX': {
    'rdrName': 'Great Falls, MT',
    'rdrLonLat': [
      -111.38527,
      47.45970
    ],
    'wfoId': 'TFX',
    'wfoName': 'Great Falls, MT',
    'neighbors': {
      'w': 'KMSX',
      'e': 'KGGW',
      'sw': 'KCBX',
      's': 'KSFX',
      'se': 'KBLX'
    }
  },
  'KTLH': {
    'rdrName': 'Tallahassee, FL',
    'rdrLonLat': [
      -84.3288,
      30.3974
    ],
    'wfoId': 'TAE',
    'wfoName': 'Tallahassee, FL',
    'neighbors': {
      'nw': 'KEOX',
      'n': 'KJGX',
      'ne': 'KVAX',
      'w': 'KEVX',
      'e': 'KJAX',
      's': 'KBYX',
      'se': 'KTBW'
    }
  },
  'KTLX': {
    'rdrName': 'Oklahoma City, OK',
    'rdrLonLat': [
      -97.2777,
      35.33305
    ],
    'wfoId': 'OUN',
    'wfoName': 'Norman, OK',
    'neighbors': {
      'nw': 'KDDC',
      'n': 'KVNX',
      'ne': 'KINX',
      'w': 'KAMA',
      'e': 'KSRX',
      'sw': 'KFDR',
      's': 'KFWS',
      'se': 'KSHV'
    }
  },
  'KTWX': {
    'rdrName': 'Topeka, KS',
    'rdrLonLat': [
      -96.23190,
      38.99690
    ],
    'wfoId': 'TOP',
    'wfoName': 'Topeka, KS',
    'neighbors': {
      'nw': 'KUEX',
      'n': 'KOAX',
      'ne': 'KDMX',
      'w': 'KGLD',
      'e': 'KEAX',
      'sw': 'KDDC',
      's': 'KICT',
      'se': 'KSGF'
    }
  },
  'KTYX': {
    'rdrName': 'Montague, NY',
    'rdrLonLat': [
      -75.67910,
      43.75580
    ],
    'wfoId': 'BTV',
    'wfoName': 'Burlington, VT',
    'neighbors': {
      'ne': 'KCXX',
      'e': 'KGYX',
      'sw': 'KBUF',
      's': 'KBGM',
      'se': 'KENX'
    }
  },
  'KUDX': {
    'rdrName': 'New Underwood, SD',
    'rdrLonLat': [
      -102.82970,
      44.12500
    ],
    'wfoId': 'UNR',
    'wfoName': 'Rapid City, SD',
    'neighbors': {
      'nw': 'KBLX',
      'n': 'KBIS',
      'ne': 'KABR',
      'w': 'KRIW',
      'e': 'KFSD',
      'sw': 'KCYS',
      's': 'KLNX'
    }
  },
  'KUEX': {
    'rdrName': 'Blue Hill, NE',
    'rdrLonLat': [
      -98.44190,
      40.32080
    ],
    'wfoId': 'GID',
    'wfoName': 'Hastings, NE',
    'neighbors': {
      'nw': 'KLNX',
      'n': 'KFSD',
      'ne': 'KOAX',
      'w': 'KGLD',
      'e': 'KOAX',
      'sw': 'KDDC',
      's': 'KICT',
      'se': 'KTWX'
    }
  },
  'KVAX': {
    'rdrName': 'Moody AFB, GA',
    'rdrLonLat': [
      -83.00170,
      30.89030
    ],
    'wfoId': 'TAE',
    'wfoName': 'Tallahassee, FL',
    'neighbors': {
      'nw': 'KMXX',
      'n': 'KJGX',
      'ne': 'KCLX',
      'w': 'KEOX',
      'e': 'KJAX',
      'sw': 'KTLH',
      's': 'KTBW',
      'se': 'KMLB'
    }
  },
  'KVBX': {
    'rdrName': 'Vandenberg AFB',
    'rdrLonLat': [
      -120.397,
      34.83854
    ],
    'wfoId': 'LOX',
    'wfoName': 'Los Angeles/Oxnard, CA',
    'neighbors': {
      'n': 'KMUX',
      'ne': 'KHNX',
      'e': 'KEYX',
      's': 'KVTX',
      'se': 'KSOX'
    }
  },
  'KVNX': {
    'rdrName': 'Vance AFB, OK',
    'rdrLonLat': [
      -98.12780,
      36.74080
    ],
    'wfoId': 'OUN',
    'wfoName': 'Oklahoma City, OK',
    'neighbors': {
      'nw': 'KDDC',
      'n': 'KICT',
      'ne': 'KSGF',
      'w': 'KAMA',
      'e': 'KINX',
      'sw': 'KFDR',
      's': 'KTLX',
      'se': 'KSRX'
    }
  },
  'KVTX': {
    'rdrName': 'Los Angeles, CA',
    'rdrLonLat': [
      -119.17940,
      34.41170
    ],
    'wfoId': 'LOX',
    'wfoName': 'Los Angeles/Oxnard, CA',
    'neighbors': {
      'nw': 'KVBX',
      'n': 'KHNX',
      'ne': 'KEYX',
      's': 'KSOX'
    }
  },
  'KVWX': {
    'rdrName': 'Owensville, IN',
    'rdrLonLat': [
      -87.72450,
      38.26020
    ],
    'wfoId': 'PAH',
    'wfoName': 'Paducah, KY',
    'neighbors': {
      'nw': 'KLSX',
      'n': 'KILX',
      'ne': 'KIND',
      'w': 'KSGF',
      'e': 'KLVX',
      'sw': 'KLZK',
      's': 'KPAH',
      'se': 'KHPX'
    }
  },
  'KYUX': {
    'rdrName': 'Yuma, AZ',
    'rdrLonLat': [
      -114.65670,
      32.49530
    ],
    'wfoId': 'PSR',
    'wfoName': 'Phoenix, AZ',
    'neighbors': {
      'nw': 'KSOX',
      'n': 'KESX',
      'ne': 'KFSX',
      'w': 'KNKX',
      'e': 'KIWA',
      'se': 'KEMX'
    }
  },
  'TJUA': {
    'rdrName': 'San Juan, PR',
    'rdrLonLat': [
      -66.0781,
      18.1156
    ],
    'wfoId': 'SJU',
    'wfoName': 'San Juan, PR',
    'neighbors': {
      'nw': 'KAMX',
      'w': 'KBYX'
    }
  },
  'PABC': {
    'rdrName': 'Bethel, AK',
    'rdrLonLat': [
      -161.8763,
      60.7919
    ],
    'wfoId': 'AFC',
    'wfoName': 'Anchorage, AK',
    'neighbors': {
      'n': 'PAEC',
      'ne': 'PAPD',
      'e': 'PAHG',
      'se': 'PAKC'
    }
  },
  'PAPD': {
    'rdrName': 'Fairbanks, AK',
    'rdrLonLat': [
      -147.5013,
      65.03511
    ],
    'wfoId': 'AFG',
    'wfoName': 'Fairbanks, AK',
    'neighbors': {
      'nw': 'PAEC',
      'w': 'PABC',
      'sw': 'PAHG',
      's': 'PAIH',
      'se': 'PACG'
    }
  },
  'PHKM': {
    'rdrName': 'Honolulu, HI',
    'rdrLonLat': [
      -155.7777,
      20.125
    ],
    'wfoId': 'HFO',
    'wfoName': 'Honolulu, HI',
    'neighbors': {
      'nw': 'PHKI',
      'w': 'PGUA',
      's': 'PHWA',
      'se': 'PHKM'
    }
  },
  'PAHG': {
    'rdrName': 'Kenai, AK',
    'rdrLonLat': [
      -151.35144,
      60.7259
    ],
    'wfoId': 'AFC',
    'wfoName': 'Anchorage, AK',
    'neighbors': {
      'nw': 'PAEC',
      'n': 'PAPD',
      'w': 'PABC',
      'sw': 'PAKC',
      'se': 'PAIH'
    }
  },
  'PAKC': {
    'rdrName': 'King Salmon, AK',
    'rdrLonLat': [
      -156.6294,
      58.6794
    ],
    'wfoId': 'AFC',
    'wfoName': 'Anchorage, AK',
    'neighbors': {
      'nw': 'PABC',
      'ne': 'PAHG',
      'e': 'PAIH',
      'se': 'PACG'
    }
  },
  'PAIH': {
    'rdrName': 'Middleton Island, AK',
    'rdrLonLat': [
      -146.3010,
      59.4619
    ],
    'wfoId': 'AFC',
    'wfoName': 'Anchorage, AK',
    'neighbors': {
      'nw': 'PAHG',
      'n': 'PAPD',
      'w': 'PAKC',
      'se': 'PACG'
    }
  },
  'PHMO': {
    'rdrName': 'Molokai, HI',
    'rdrLonLat': [
      -157.18026,
      21.1327
    ],
    'wfoId': 'HFO',
    'wfoName': 'Honolulu, HI',
    'neighbors': {
      'nw': 'PHKI',
      'w': 'PGUA',
      's': 'PHWA',
      'se': 'PHKM'
    }
  },
  'PAEC': {
    'rdrName': 'Nome, AK',
    'rdrLonLat': [
      -165.2949,
      64.51139
    ],
    'wfoId': 'AFG',
    'wfoName': 'Fairbanks, AK',
    'neighbors': {
      'e': 'PAPD',
      's': 'PABC',
      'se': 'PAHG'
    }
  },
  'PACG': {
    'rdrName': 'Sitka, AK',
    'rdrLonLat': [
      -135.5291,
      56.8527
    ],
    'wfoId': 'AJK',
    'wfoName': 'Juneau, AK',
    'neighbors': {
      'nw': 'PAIH',
      'n': 'PAPD'
    }
  },
  'PHKI': {
    'rdrName': 'South Kauai, HI',
    'rdrLonLat': [
      -159.5524,
      21.893
    ],
    'wfoId': 'HFO',
    'wfoName': 'Honolulu, HI',
    'neighbors': {
      'w': 'PGUA',
      'se': 'PHMO'
    }
  },
  'PHWA': {
    'rdrName': 'South Shore, HI',
    'rdrLonLat': [
      -155.56887,
      19.094
    ],
    'wfoId': 'HFO',
    'wfoName': 'Honolulu, HI',
    'neighbors': {
      'nw': 'PHMO',
      'ne': 'PHKM',
      'w': 'PGUA'
    }
  },
  'PGUA': {
    'rdrName': 'Agana, GU',
    'rdrLonLat': [
      144.81111,
      13.45583
    ],
    'wfoId': 'GUM',
    'wfoName': 'Tiyan, Guam',
    'neighbors': {
      'ne': 'PHKI',
      'e': 'PHWA'
    }
  }
}
