import Ajv from 'ajv'

export class SchemaValidator {
  constructor ({ schemas = [] } = {}) {
    this.__validator = new Ajv({
      allErrors: true,
      schemas: schemas,
      useDefault: true
    })
  }

  validate (name, config) {
    if (!this.__validator.validate(name, config)) {
      throw new SchemaValidationError('Schema validation error occurred', config, this.__validator.errors)
    }
  }
}

export class SchemaValidationError extends Error {
  constructor (message, config, errors) {
    if (!message) {
      message = 'Schema validation error occurred'
    }
    super(message + ': ' + JSON.stringify(errors))
    this.config = config
    this.errors = errors
  }
}
