<template>
  <a
    v-if="eventValue"
    :class="buttonClasses"
    :style="buttonStyles"
    @click="emitClick"
  >
    <slot>
      NO LABEL
    </slot>
  </a>
  <a
    v-else
    :class="buttonClasses"
    :style="buttonStyles"
    :href="url"
  >
    <slot>
      NO LABEL
    </slot>
  </a>
</template>

<script>
export default {
  props: {
    connect: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    eventValue: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    treatments: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: '#'
    },
    width: {
      type: String,
      default: null
    }
  },
  computed: {
    buttonClasses () {
      const classes = {
        button: true,
        disabled: this.disabled,
        primary: this.type === 'primary' && !this.disabled,
        callto: this.type === 'callto' && !this.disabled,
        large: this.size === 'large',
        small: this.size === 'small',
        'connect-left': this.connect.find(element => element === 'left'),
        'connect-right': this.connect.find(element => element === 'right')
      }

      if (this.treatments !== null) {
        this.treatments.forEach(treatment => {
          classes[treatment] = true
        })
      }

      return classes
    },
    buttonStyles () {
      let styles = {}

      if (this.width !== null) {
        styles.width = this.width
      }

      return styles
    }
  },
  methods: {
    emitClick () {
      if (!this.disabled) {
        this.$emit('button:click', this.eventValue)
      }
    }
  }
}
</script>

<style scoped>
.button {
  cursor: pointer;
  border: 0;
  border-radius: 0.3rem;
  font-family: 'Source Sans Pro', 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  font-weight: 700;
  line-height: 1;
  margin-left: 0.2em;
  margin-bottom: 0.2em;
  outline: none;
  padding: 0.8rem 1.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background-color: #fff;
  color: #0071bc;
  box-shadow: inset 0 0 0 2px #0071cc;
}

.button:link,
.button:hover,
.button:visited,
.button:active {
  text-decoration: none;
}

.button:hover {
  background-color: #f3f3f3;
}

.button.primary {
  background-color: #0071bc;
  box-shadow: inset 0 0 0 2px #0071bc;
  color: #fff;
}

.button.primary:hover {
  background-color: #025892;
}

.button.callto {
  background-color: #f60;
  box-shadow: inset 0 0 0 2px #f60;
  border: none;
  color: #fff;
}

.button.callto:hover {
  background-color: #333;
  box-shadow: inset 0 0 0 2px #333;
}

.button.disabled {
  background-color: #efefef;
  box-shadow: inset 0 0 0 2px #efefef;
  color: #aaa;
  cursor: default;
}

.button.large {
  font-size: 120%;
}

.button.small {
  font-size: 75%;
  padding: 0.4rem 1rem;
}

.button.connect-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button.connect-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.button.standout {
  display: block;
  padding: 10px 20px;
  margin: 10px 0;
}
</style>
