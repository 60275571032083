import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from 'vue-gtag'
import deepmerge from 'deepmerge'

import FallbackPlugin from './plugins/fallback'
import LogPlugin from './plugins/log'
import TemplatePlugin from './plugins/template'

import App from './App.vue'
import { createStore } from './store'
import favicon from './assets/favicon.ico'
import routes from './router/routes'
import './app.css'

const TRACKER_DEFAULTS = {
  params: {
    send_page_view: false // let VueRouter set page views
  },
  appName: 'NWS-Radar',
  pageTrackerScreenviewEnabled: true
}

Vue.config.productionTip = false

function buildLocalRoutes (routes) {
  const localRoutes = routes.map(route => {
    return {
      text: route.localRoutesTitle,
      href: route.localRouterDefaultPath || route.path,
      order: route.localRoutesOrder,
      items: route.children ? buildLocalRoutes(route.children) : []
    }
  })

  return localRoutes.sort((a, b) => a.order - b.order)
}

function buildTrackerConfig (state) {
  if (state.trackerIds) {
    console.log('Analytics enabled')
    let trackerIds = []

    if (state.trackerIds && state.trackerIds.length > 0) {
      trackerIds = state.trackerIds.split(',')
    }

    if (trackerIds.length > 0) {
      // first id is used as primary tracker id
      let trackerConfig = {
        config: deepmerge(TRACKER_DEFAULTS, {})
      }
      trackerConfig.config.id = trackerIds[0]

      // all other ids are secondary
      let trackerConfigIncludes = []

      for (let x = 1; x < trackerIds.length; x++) {
        let include = deepmerge(TRACKER_DEFAULTS, {})
        include.id = trackerIds[x]
        trackerConfigIncludes.push(include)
      }

      if (trackerConfigIncludes.length > 0) {
        trackerConfig.includes = trackerConfigIncludes
      }
      return trackerConfig.config
    }
  }
  return null
}

const router = new VueRouter({
  mode: 'history',
  routes
})

Vue.use(VueRouter)

export function createApp (state, server = false) {
  Vue.use(FallbackPlugin)
  Vue.use(LogPlugin, { logEvents: false, disabled: server })
  Vue.use(TemplatePlugin, {
    localRoutes: buildLocalRoutes(routes),
    weatherUrl: state.weatherUrl
  })

  const trackerConfig = buildTrackerConfig(state)
  if (trackerConfig) {
    Vue.use(VueGtag, {
      config: trackerConfig
    }, router)
  }

  const app = new Vue({
    components: { App },
    render: createElement => createElement('app'),
    router,
    store: createStore(state)
  })

  return { app, favicon }
}
