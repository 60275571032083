import { getLogger } from '../log'

const DATA_PREFIX = '$_fallbackProps$'
const COMPUTED_PREFIX = '$'

/**
 * The fallback plugin adds an option to let props fall back on local data
 * properties if the parent doesn't utilize them.
 *
 * The new option is "fallback" and can be a non-empty string or Boolean value.
 * This will create a new computed property that will use the prop if it is
 * defined but fall back on an internal data property if it is not.  Updates
 * will always generate the "update:<prop name>" event whether or not the prop
 * is defined.
 *
 * If "fallback" is a literal boolean true, the computed property will have the
 * same name as the prop but prefixed with a $ (so the computed property
 * *$foobar* for the prop *foobar*).  However, if "fallback" is a string, the
 * computed property will have the string as the name instead.
 */
function plugin (Vue, options = {}) {
  Vue.mixin({
    beforeCreate () {
      const props = this.$options.props || {}
      // Ignore props declared as simple arrays
      if (Array.isArray(props)) {
        return
      }

      let dataAdditions = {}
      for (const name of Object.keys(props)) {
        let prop = props[name]
        // Ignore props without a "fallback" value set
        if (!typeof prop === 'object' || !prop.hasOwnProperty('fallback')) {
          continue
        }
        const fallback = prop.fallback
        if (!fallback) {
          continue
        }

        const computedName = fallback === true ? COMPUTED_PREFIX + name : fallback
        dataAdditions[DATA_PREFIX + name] = typeof prop.default === 'function' ? prop.default() : prop.default
        prop.default = undefined

        if (!Array.isArray(prop.type) && ![null, undefined].includes(prop.type)) {
          prop.type = [prop.type]
        }
        // Accommodate Vue's insistence on boolean false coercion
        if (Array.isArray(prop.type) && prop.type.length === 1 && prop.type[0] === Boolean) {
          prop.type.push(undefined)
        }

        if (!this.$options.hasOwnProperty('computed')) {
          this.$options.computed = {}
        }

        this.$options.props[name] = prop
        this.$options.computed[computedName] = computedFns(name)
      }

      if (Object.keys(dataAdditions).length > 0) {
        const data = this.$options.data
        this.$options.data = () => {
          if (typeof data !== 'function') {
            getLogger().warning(`Data for ${this.$vnode.tag} should be a function but is not`)
            return Object.assign({}, data, dataAdditions)
          } else {
            return Object.assign({}, data(), dataAdditions)
          }
        }
      }
    }
  })
}

export default plugin

function computedFns (name) {
  return {
    get () {
      return this[name] === undefined ? this.$data[DATA_PREFIX + name] : this[name]
    },
    set (value) {
      if (this[name] === undefined) {
        this.$data[DATA_PREFIX + name] = value
      }
      this.$emit(`update:${name}`, value)
    }
  }
}
