<template>
  <ul
    v-show="isOpen"
    :class="{ 'treelist-root': depth == 0 }"
    class="treelist"
  >
    <template-menu
      v-for="(item, index) in items"
      :key="depth + '-' + index"
      :items="item.items"
      :href="item.href"
      :label="item.text"
      :depth="depth + 1"
    />
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      default: () => [],
      type: Array
    },
    depth: {
      default: 0,
      type: Number
    },
    isOpen: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    toggleOpen () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped>
ul.treelist {
  margin: 0 0 0 10px;
  padding: 0;
  list-style-type: none;
}

ul.treelist-root {
  border-left: 1px solid #cdcdcd;
  margin-top: 10px;
}
</style>
