import Enhanced from '../components/pages/Enhanced.vue'
import Standard from '../components/pages/Standard.vue'

// Refer to server/setup-server.js for route history settings

export default [
  {
    name: 'enhanced',
    path: '/',
    component: Enhanced,
    meta: {
      fullscreen: true
    },
    localRoutesTitle: 'Enhanced Radar',
    localRoutesOrder: 0,
    props: route => ({ query: route.query }),
    alias: [
      '/station/:id',
      '/station/:id/enhanced',
      '/region/:id',
      '/region/:id/enhanced',
      '/location'
    ]
  },
  {
    name: 'standard',
    path: '/standard/:id?/:product?',
    component: Standard,
    meta: {
      fullscreen: false
    },
    localRoutesTitle: 'Standard Radar (Low-Bandwidth)',
    localRoutesOrder: 1,
    localRouterDefaultPath: '/standard',
    props: route => ({ query: route.query }),
    alias: [
      '/station/:id/standard',
      '/region/:id/standard',
      '/station/:id/:product/standard',
      '/region/:id/:product/standard'
    ]
  }
]
