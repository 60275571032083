<template>
  <article>
    <div class="banner-official">
      <img
        :src="usflag"
        alt="U.S. flag"
      >
      <p>An official website of the United States government</p>
    </div>
    <div class="content">
      <p>Answers to common questions on our <a :href="`${weatherUrl}/help`">FAQ</a> and <a :href="`${weatherUrl}/glossary`">Glossary</a> or <a :href="`${weatherUrl}/contact`">contact us.</a></p>
      <p>Learn more about the <a :href="`${weatherUrl}/about`">National Weather Service mission and vision.</a></p>
      <p>Discover <a :href="`${weatherUrl}/careers`">career opportunities</a> at the National Weather Service.</p>
      <h1>Legal Information</h1>
      <ul class="list-unstyled">
        <li><a :href="`${weatherUrl}/disclaimer`">Disclaimer</a></li>
        <li><a href="https://www.cio.noaa.gov/services_programs/info_quality.html">Information Quality</a></li>
        <li><a :href="`${weatherUrl}/privacy`">Privacy Policy</a></li>
        <li><a href="https://www.noaa.gov/foia-freedom-of-information-act">Freedom of Information Act (FOIA)</a></li>
      </ul>
      <br>
      National Weather Service<br>
      1325 East West Highway, Silver Spring, MD 20910<br>
      <br>
      <a href="https://www.noaa.gov">
        <img
          :src="imageNoaaLogo"
          alt="NOAA logo"
          class="noaa-logo"
        >
      </a><br>
    </div>
  </article>
</template>

<script>
import usflag from '../../assets/usflag.png'
import imageNoaaLogo from '../../assets/noaa-logo.png'
import { getConfig } from '../../config'

export default {
  data () {
    return {
      usflag: usflag,
      imageNoaaLogo: imageNoaaLogo
    }
  },
  computed: {
    weatherUrl () {
      return getConfig().weatherUrl
    }
  }
}
</script>

<style scoped>
.content {
  padding: 20px 20px 20px 10px;
}

.content h1 {
  font-size: 1.2em;
}

.content p:first-child {
  padding-top: 0;
  margin-top: 0;
}

.content .noaa-logo {
  width: 260px;
}

.banner-official {
  background: #f1f1f1;
  line-height: 2.2em;
  padding: 0 10px;
}

.banner-official img {
  box-sizing: content-box;
  background: #f1f1f1;
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

/* stylelint-disable-next-line no-descending-specificity */
.banner-official p {
  display: inline-block;
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 12px;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
</style>
