<template>
  <aside
    class="panel"
  >
    <div class="content">
      <slot />
    </div>
    <div class="action">
      <div
        class="button close"
        @click="$emit('panel:close')"
      >
        <font-awesome-icon
          class="button-icon fa-lg"
          icon="times"
        /> Close
      </div>
      <div
        v-if="showExplore"
        class="button explore"
        @click="$emit('panel:toggle', 'explore')"
      >
        <font-awesome-icon
          class="button-icon fa-lg"
          icon="th"
        /> Explore More Weather
      </div>
      <div
        v-else
        class="button shell"
      >
        &nbsp;
      </div>
    </div>
  </aside>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faTh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faTimes, faTh)

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    showExplore: {
      default: true,
      type: Boolean
    }
  }
}
</script>

<style scoped>
.panel {
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  max-width: 500px;
  box-shadow: 6px 4px 12px 0 rgb(0, 0, 0, 0.2);
  z-index: 200000;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 46px;
  overflow: auto;
  font-size: 92%;
}

.action {
  display: flex;
  flex: 1 auto;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #0099d8;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.action .button.close {
  width: 120px;
}

.action .button.explore {
  flex: 1;
}

.action .button.shell {
  flex: 1;
  cursor: default;
}

.button-icon {
  margin-right: 10px;
}
</style>
