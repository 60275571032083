<template>
  <div id="app">
    <header>
      <template-banner @panel:toggle="togglePanel" />
    </header>
    <template-panel
      v-show="$panel !== null"
      :show-explore="$panel !== 'explore'"
      @panel:close="closePanel"
      @panel:toggle="togglePanel"
    >
      <template-panel-help v-show="$panel === 'help'" />
      <template-panel-local v-show="$panel === 'local'">
        <slot
          slot="description"
          name="description"
        />
        <slot
          slot="support"
          name="support"
        />
        <slot
          slot="guide"
          name="guide"
        />
      </template-panel-local>
      <template-panel-explore v-show="$panel === 'explore'" />
      <slot name="panels" />
    </template-panel>
    <main
      :class="format"
      class="main"
    >
      <slot />
    </main>
  </div>
</template>

<script>
import { getConfig } from '../config'
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css'
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config as FAConfig } from '@fortawesome/fontawesome-svg-core'
FAConfig.autoAddCss = false /* eslint-disable import/first */

export default {
  props: {
    panel: {
      type: String,
      default: null,
      fallback: true
    }
  },
  data () {
    return {
      format: 'scroll'
    }
  },
  watch: {
    $route (to, from) {
      this.$panel = null
      this.toggleFormat(to.meta.fullscreen)
    }
  },
  mounted () {
    this.toggleFormat(this.$route.meta.fullscreen)
  },
  methods: {
    toggleFormat (fullscreen) {
      this.format = fullscreen ? 'fullscreen' : 'scroll'
    },
    togglePanel (name) {
      getConfig().logger.debug(`Toggling template panel: ${name}`)
      this.$panel = this.$panel === name ? null : name

      if (this.$gtag && this.$panel !== null) {
        this.$gtag.event('panel', {
          event_category: 'radar-menu',
          event_label: name
        })
      }
    },
    closePanel () {
      getConfig().logger.debug('Closing template panel')
      this.$panel = null
    }
  }
}
</script>

<style scoped>
.main {
  color: #333;
  background: white;
}

.main.scroll {
  margin-top: 56px;
  padding: 10px 20px 20px 10px;
  max-width: 1000px;
}

.main.fullscreen {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.main.fullscreen article {
  height: 100%;
  width: 100%;
}
</style>
