const NOOP = () => {}
const CONSOLE_LOG = console.log.bind(console)
const CONSOLE_WARNING = console.hasOwnProperty('warn') ? console.warn.bind(console) : CONSOLE_LOG
const CONSOLE_ERROR = console.hasOwnProperty('error') ? console.error.bind(console) : CONSOLE_LOG

export const LEVELS = {
  DEBUG: 1,
  INFO: 2,
  WARNING: 3,
  ERROR: 4,
  SILENT: 5
}

let defaultLevel = process.env.NODE_ENV === 'production' ? LEVELS.INFO : LEVELS.DEBUG
let enabled = true

export function disable () {
  enabled = false
}

export function enable () {
  enabled = true
}

export function getDefaultLevel () {
  return defaultLevel
}

export function setDefaultLevel (level) {
  if (typeof level === 'number' && Object.values(LEVELS).includes(level)) {
    defaultLevel = level
  } else if (typeof level === 'string' && Object.keys(LEVELS).includes(level.toUpperCase())) {
    defaultLevel = LEVELS[level.toUpperCase()]
  } else {
    throw new Error(`Invalid level specified: ${level} (${typeof level})`)
  }
}

export class Logger {
  constructor (name, level = null, enabled = true) {
    this.__enabled = enabled
    this.__level = level
    this.__name = name

    if (level !== null) {
      this.setLevel(level)
    }
  }

  // LOGGING FUNCTIONS

  get debug () {
    return (this.enabled && this.level <= LEVELS.DEBUG) ? CONSOLE_LOG : NOOP
  }

  get info () {
    return (this.enabled && this.level <= LEVELS.INFO) ? CONSOLE_LOG : NOOP
  }

  get warning () {
    return (this.enabled && this.level <= LEVELS.WARNING) ? CONSOLE_WARNING : NOOP
  }

  get error () {
    return (this.enabled && this.level <= LEVELS.ERROR) ? CONSOLE_ERROR : NOOP
  }

  // LOGGING LEVELS

  get enabled () {
    return this.__enabled && enabled
  }

  get level () {
    return this.__level || defaultLevel
  }

  disable () {
    this.__enabled = false
  }

  enable () {
    this.__enabled = true
  }

  getLevel () {
    return this.level
  }

  setLevel (level) {
    if (level === null) {
      this.__level = null
    } else if (typeof level === 'number' && Object.values(LEVELS).includes(level)) {
      this.__level = level
    } else if (typeof level === 'string' && Object.keys(LEVELS).includes(level.toUpperCase())) {
      this.__level = LEVELS[level.toUpperCase()]
    } else {
      throw new Error(`Invalid level specified: ${level} (${typeof level})`)
    }
  }
}

const LOGGERS = {}

export function getLogger (name) {
  if (name === undefined) {
    name = '_main'
  }

  if (!LOGGERS.hasOwnProperty(name)) {
    LOGGERS[name] = new Logger(name)
  }

  return LOGGERS[name]
}

export function getLoggers () {
  return LOGGERS
}
