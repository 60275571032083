<template>
  <template-page>
    <router-view />
    <template slot="description">
      <p>The NWS Radar site displays the radar on a map along with forecast and alerts. The radar products are also available as <a :href="gisUrl">OGC compliant services</a> to use in your application.</p>
    </template>
    <template slot="support">
      <p>Review <a :href="`${weatherUrl}/radarfaq`">the Radar FAQ</a> for help with the transition to the new site. Contact <a href="mailto:nco.ops@noaa.gov">the helpdesk</a> for operational issues. Visit <a :href="`${weatherUrl}/gis/cloudgiswebservices`">IDP-GIS web services</a> for questions about geospatial services.</p>
    </template>
    <template slot="guide">
      <p>This site is organized into views that provide relevant radar products and weather information for a common task or goal.</p>
      <ul>
        <li>
          <p><strong>Radar station products</strong></p>
          <p>This view provides specific radar products for a selected radar station and storm based alerts.</p>
        </li>
        <li>
          <p><strong>National radar mosaic</strong></p>
          <p>This view combines radar station products into a single layer called a mosaic and storm based alerts.</p>
        </li>
        <li>
          <p><strong>Weather for a location</strong></p>
          <p>This view is similar to a radar application on a phone that provides radar, current weather, alerts and the forecast for a location. Recommended for bookmarks.</p>
        </li>
      </ul>
      <h3>Bookmarks</h3>
      <p>The URL will automatically update as you select the view and settings. You may bookmark the URL to return later to the same view with the selected settings. For example, if you select "Weather for a location," then select a location, the bookmark will return to your location on your next visit.</p>
      <p>On mobile devices, you can save the bookmark as an easy-access icon similar to other apps. Please consult your device documentation for instructions.</p>
    </template>
  </template-page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'gisUrl',
      'weatherUrl'
    ])
  }
}
</script>
